import '../../../base/Popup.scss';
import './SectorDuplicateConfirmation.scss';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setCurrentTab } from 'redux/slices/adminMenu';
import { Menus } from 'constants/index';
import { setType } from 'redux/slices/popup';
import { goTo } from 'redux/slices/manageSectors';
import { ManageStep } from 'constants/enums';

export function SectorDuplicateConfirmation() {
  const { t } = useTranslation(['popup']);
  const dispatch = useDispatch();

  const onClickValidationEvent = async () => {
    dispatch(setCurrentTab(Menus.STEPS));
    dispatch(goTo(ManageStep.detail));
    dispatch(setType(''));
  };

  const onClickCloseEvent = async () => {
    dispatch(setType(''));
  };

  return (
    <div>
      <p className="duplicate-popup-confirm__warning">{t('Bravo !')}</p>
      <p className="duplicate-popup-confirm__warning">
        Le secteur / métier a bien été dupliqué. Souhaitez-vous modifier <br></br> ses étapes ?
      </p>
      <Link className="duplicate-popup-confirm__link" to="/admin" onClick={onClickValidationEvent}>
        {t('Allons-y')}
      </Link>
      <Link className="duplicate-popup-confirm__link" to="/admin" onClick={onClickCloseEvent}>
        {t('Pas maintenant')}
      </Link>
    </div>
  );
}
