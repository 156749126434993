import { MutableRefObject, RefObject, useEffect, useRef, useState } from 'react';

export function useHover(): [MutableRefObject<any>, boolean] {
  const [value, setValue] = useState(false);

  const ref: any = useRef(null);

  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);

  useEffect(() => {
    const currentRef = ref.current;

    if (currentRef) {
      currentRef.addEventListener('mouseover', handleMouseOver);
      currentRef.addEventListener('mouseout', handleMouseOut);
    }

    return () => {
      currentRef ? currentRef.removeEventListener('mouseover', handleMouseOver) : null;
      currentRef ? currentRef.removeEventListener('mouseout', handleMouseOut) : null;
    };
  }, [ref.current]);

  return [ref, value];
}
