import './editCardForm.scss';
import MoveIcon from 'assets/icons/move-icon.svg';
import { Button } from 'views/buttons/base';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { Card, CardType, Layout, Lot, Positions, ZonesTypeMap } from 'constants/index';
import {
  removeFilename,
  selectEditLot,
  selectFilenames,
  setEditionStepToMap,
  setEditionStepToNewCard,
  setEditionStepToZones,
  setEditLot
} from 'redux/slices/lotEdition';
import { setCards, setCreatedLot, setIsFromEditCards } from 'redux/slices/lotCreation';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useEffect, useMemo } from 'react';
import { Card as CardComponent } from 'views/components/base/cards/Card';
import { updateCards } from 'services/card/cardService';
import { editLot as editLotAPI, unpublishLot } from 'services/lot/lotService';
import fileEncoding from 'utils/file/fileEncoding';
import { addFile } from 'services/file/fileService';
import { ReactComponent as LeftArrow } from 'assets/icons/arrow-left-icon.svg';
import { setNotificationMessage, setNotificationType } from 'redux/slices/notification';
import { setZonesTypeMap } from 'redux/slices/zones';

export function EditCardForm() {
  const dispatch = useAppDispatch();

  const editLot = useAppSelector(selectEditLot);
  const fileNames = useAppSelector(selectFilenames);
  const ResponsiveGridLayout = useMemo(() => WidthProvider(Responsive), []);

  // NOTE: each time cards array change, re-render the card list
  useEffect(() => {
    // If there is no cards in lot, unpublish the lot
    if (editLot.cards.length === 0 && editLot._id) {
      unpublishLot(editLot._id);
    }
  }, [editLot]);

  const redirectToLotForm = () => {
    dispatch(setCreatedLot(editLot as Lot));
    dispatch(setCards(editLot.cards));
    dispatch(setEditionStepToNewCard());
    dispatch(setIsFromEditCards(true));
  };

  const saveSortedCards = async () => {
    let updatedCards = null;
    let base64Images: Card[] = [];

    if (editLot.cards.length > 0 && editLot._id !== undefined) {
      try {
        let cardsOrderForApi: Card[] = JSON.parse(JSON.stringify(editLot.cards));

        for (let card of cardsOrderForApi) {
          if (card.imageUrl) {
            if (fileEncoding.isBase64Encoded(card.imageUrl) && fileNames) {
              const imageFile = await fileEncoding.toFile(card.imageUrl, fileNames[card._id as string] as string);
              const imageFileServer = await addFile(imageFile);
              const cardPath = imageFileServer.path.split('/uploads')[1];

              card = { ...card, imageUrl: `${process.env.REACT_APP_PATH}${cardPath}` };

              base64Images.push(card);

              dispatch(removeFilename(card._id as string));
            }
          }
        }

        // Change imageUrl of Cards with server path url
        base64Images.forEach((card: Card) => {
          cardsOrderForApi.filter((x: Card) => x._id === card._id).forEach((x: Card) => (x.imageUrl = card.imageUrl));
        });

        // We update cards of lotEdition store with
        // non base64 urls card images
        dispatch(setEditLot({ ...editLot, cards: cardsOrderForApi }));
        updatedCards = await updateCards(editLot._id, cardsOrderForApi);
      } catch (error) {
        console.log(error);
      }
    }
    return updatedCards;
  };

  const finishEdit = async () => {
    dispatch(setZonesTypeMap(ZonesTypeMap.edition));
    dispatch(setEditionStepToZones());
  };

  const editAndFinishCardsInDb = async () => {
    let updatedLot = null;
    let updatedCards = null;

    if (editLot.cards.length > 0 && editLot._id) {
      try {
        dispatch(setNotificationType('loading'));
        dispatch(setNotificationMessage('Sauvegarde des cartes en cours...'));

        updatedCards = await saveSortedCards();
        updatedLot = await editLotAPI(editLot._id, { ...editLot, cards: updatedCards });

        if (updatedLot && updatedCards) {
          dispatch(setEditLot(updatedLot));
          dispatch(setNotificationType('validation'));
          dispatch(setNotificationMessage('Les cartes ont bien été sauvegardées'));
          finishEdit();
        }
      } catch (error) {
        console.log(error);
        dispatch(setNotificationType('error'));
        dispatch(setNotificationMessage('Une erreur est survenue lors de la sauvegarde des cartes'));
      }
    }
  };

  const onDrag = () => {
    document.body.style.cursor = `url(${MoveIcon}) 16 16, move`;
  };

  const onDragStop = (e: any) => {
    document.body.style.cursor = 'default';
    const cardsSorted: Card[] = [];
    const sortedLayout = e
      // sort layout items in the new order
      .sort((a: any, b: any) => {
        // same row, compare X
        if (a.y === b.y) {
          return a.x - b.x;
        }

        // different row, compare Y
        return a.y - b.y;
      });
    sortedLayout.forEach((layoutItem: Layout) => {
      const card = editLot.cards.find((item) => item._id === layoutItem.i);
      if (card) {
        cardsSorted.push(card);
      }
    });
    if (cardsSorted.length > 0) {
      dispatch(setEditLot({ ...editLot, cards: cardsSorted }));
    }
  };

  const nbColumns = () => {
    if (window.innerWidth >= 960 && window.innerWidth < 1200) {
      return 3;
    } else if (window.innerWidth > 1200 && window.innerWidth < 1500) {
      return 4;
    } else {
      return 5;
    }
  };

  const gridCards = useMemo(() => {
    return editLot.cards.map((card: Card, index: number) => {
      return (
        <div
          data-id={card._id}
          key={card._id}
          data-grid={{ x: index % nbColumns(), y: Math.floor(index / nbColumns()), w: 1, h: 1 }}
        >
          <CardComponent cardType={CardType.edition} card={card} />
        </div>
      );
    });
  }, [editLot.cards]);

  return (
    <>
      <p className="edit-card-form__lot-title">{editLot.title}</p>
      <div className="edit-card-form">
        {editLot.cards.length > 0 && (
          <ResponsiveGridLayout
            compactType="horizontal"
            className="edit-card-form__layout"
            autoSize
            rowHeight={285}
            draggableHandle=".draggable-handle"
            isDraggable
            isDroppable
            isResizable={false}
            margin={[20, 20]}
            onDrag={onDrag}
            onDragStop={onDragStop}
            preventCollision={false}
            transformScale={1}
            useCSSTransforms={false}
            breakpoints={{ lg: 1500, md: 1200, sm: 768, xs: 480, xxs: 0 }}
            cols={{ lg: 5, md: 4, sm: 3, xs: 3, xxs: 2 }}
          >
            {gridCards}
          </ResponsiveGridLayout>
        )}
        {editLot.cards.length === 0 && (
          <div className="edit-card-form__no-card-container">
            <p>
              Vous n'avez pas encore créé de carte. <br />
              Veuillez en ajouter une.
            </p>
          </div>
        )}
        <div className="edit-card-form__buttons">
          <Button
            label="previous"
            type="button"
            classType="secondary-button-lg"
            translation="editLot"
            onClick={() => dispatch(setEditionStepToMap())}
            Icon={{
              Svg: LeftArrow,
              position: Positions.START
            }}
          />
          <Button
            label="addCard"
            type="button"
            classType="secondary-button-add-card"
            translation="editLot"
            onClick={redirectToLotForm}
          />
          <Button
            label="finishCards"
            type="button"
            disabled={editLot.cards.length === 0}
            classType="primary-button-add-card"
            translation="editLot"
            onClick={editAndFinishCardsInDb}
          />
        </div>
      </div>
    </>
  );
}
