import { Card, CardDragStopHook } from 'constants/index';
import { useState } from 'react';
import { DraggableData, DraggableEvent } from 'react-draggable';
import { useDispatch } from 'react-redux';
import { setIsDrag } from 'redux/slices/game';

export const useCardDragEvents = (
  onCardDragStop: (zoneId: string, card: Card) => boolean,
  cardDropped: (card: Card, index: number) => void
): CardDragStopHook => {
  const [hookShrinkCard, setHookShrinkCard] = useState<boolean>(false);
  const [hookDraggableCardPosition, setHookDraggableCardPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const dispatch = useDispatch();

  // TODO: add a flag in order to implemement behavior for gamemap controls
  const onStop = (e: DraggableEvent, card: Card, index: number) => {
    setHookShrinkCard(false);
    dispatch(setIsDrag(false));
    const draggableElement = e.target as HTMLElement;
    if (draggableElement && onCardDragStop) {
      const zoneId = draggableElement.getAttribute('data-id');
      if (zoneId) {
        const hasBeenDroppedOnZone = onCardDragStop(zoneId, card);
        if (hasBeenDroppedOnZone) {
          // if the card was dropped on a zone, replace the draggable element at the original position for the next card
          setHookDraggableCardPosition({ x: 0, y: 0 });
          // handle card drop
          cardDropped(card, index);
        }
      }
    }
  };

  const onDrag = (e: DraggableEvent, card: Card, positionData: DraggableData) => {
    if (positionData.x < -50) {
      setHookShrinkCard(true);
    }
  };

  return { onStop, hookShrinkCard, onDrag, hookDraggableCardPosition };
};
