import { Sector } from 'constants/index';
import { apiUtils } from 'services/utils';

export const createSector = async (sectorInfo: Sector) => {
  try {
    const sector = await apiUtils.apiPost(`${process.env.REACT_APP_API_URL}/secteurs`, sectorInfo);
    return sector;
  } catch (error) {
    console.log(error);
  }
};

export const getSectorById = async (id: string) => {
  try {
    const sector = await apiUtils.apiGet(`${process.env.REACT_APP_API_URL}/secteurs/${id}`);
    return sector;
  } catch (error) {
    console.log(error);
  }
};

export const getAllSectors = async () => {
  try {
    const sectors = await apiUtils.apiGet(`${process.env.REACT_APP_API_URL}/secteurs`);
    return sectors;
  } catch (error) {
    console.log(error);
  }
};

export const editSector = async (data: { id: string; label: string; etapes?: any }) => {
  try {
    const sector = await apiUtils.apiPut(`${process.env.REACT_APP_API_URL}/secteurs`, data);
    return sector;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const checkSectorLabel = async (label: string) => {
  try {
    const isExist = await apiUtils.apiGet(`${process.env.REACT_APP_API_URL}/secteurs/check-label/${label}`);
    return isExist;
  } catch (error) {
    console.log(error);
  }
};

export const deleteSector = async (id: string) => {
  try {
    const resp = await apiUtils.apiDelete(`${process.env.REACT_APP_API_URL}/secteurs/${id}`);
    return resp;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const publishSector = async (id: string) => {
  try {
    const resp = await apiUtils.apiGet(`${process.env.REACT_APP_API_URL}/secteurs/${id}/publish`);
    return resp;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const unpublishSector = async (id: string) => {
  try {
    const resp = await apiUtils.apiGet(`${process.env.REACT_APP_API_URL}/secteurs/${id}/unpublish`);
    return resp;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
