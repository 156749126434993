import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { ProgressionState } from 'constants/types';
import { RootState } from 'redux/store';

const initialState: ProgressionState = {
  progression: undefined
};

export const progressionSlice = createSlice({
  name: 'progression',
  initialState,
  reducers: {
    setProgression: (state, action: PayloadAction<{ progression: any }>) => {
      state.progression = action.payload.progression;
    }
  }
});

export const { setProgression } = progressionSlice.actions;
export const selectProgression = (state: RootState) => state.progression.progression;

export default progressionSlice.reducer;
