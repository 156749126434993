import { CreateCard } from './createCardForm/createCardForm';
import { CreateLotForm } from './createLotForm/createLotForm';
import { CreateLotMap } from './createLotMap/createLotMap';
import { CreateCardZones } from './createCardZones/createCardZones';
import { CreateVideoForm } from './createVideoForm/createVideoForm';
import { LotCreationSteps } from 'constants/index';
import { useAppSelector } from 'redux/hooks';
import { selectCurrentStep } from 'redux/slices/lotCreation';

export function CreateLot() {
  let step: LotCreationSteps = useAppSelector(selectCurrentStep);

  return (
    <>
      {step === LotCreationSteps.lot && <CreateLotForm />}
      {step === LotCreationSteps.map && <CreateLotMap />}
      {step === LotCreationSteps.cards && <CreateCard />}
      {step === LotCreationSteps.zones && <CreateCardZones />}
      {step === LotCreationSteps.video && <CreateVideoForm />}
    </>
  );
}
