import { Card, LotType, ZoneProps } from 'constants/index';
import { useEffect, useState } from 'react';
import Draggable, { DraggableEvent } from 'react-draggable';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'redux/hooks';
import { selectIsDrag, selectNbRemainingCardsInZone, setCardsPopup } from 'redux/slices/game';
import { setPopupData, setType } from 'redux/slices/popup';
import './zone.scss';
import EditZoneIcon from 'assets/icons/edit-zone.svg';
import CloseIcon from 'assets/icons/white-cross.svg';
import CheckIcon from 'assets/icons/validation-white-icon.svg';
import { setZoneToEdit } from 'redux/slices/adminMap';
import {
  selectNotificationGame,
  selectNotificationGameZoneId,
  setNotificationGameZone,
  setNotificationGameZoneId
} from 'redux/slices/notification';
import { selectProgression } from 'redux/slices/progression';

export function MapZone({
  zoneId,
  x,
  y,
  isAdminEdit,
  onDragStop,
  zoneRef,
  cardsInSlot,
  solutionAndFactualLotsIds,
  currentLotId,
  canReceiveCardFromLot,
  isGame,
  isTutorial,
  isEditLot,
  bufferX,
  bufferY,
  textInfo,
  isProgression
}: ZoneProps) {
  const dispatch = useDispatch();

  // vars to determine if we must diplay the data from factual and solution in this zone
  const [isCurrentLotFactual, setIsCurrentLotFactual] = useState<boolean>(false);
  const [isCurrentLotSolution, setIsCurrentLotSolution] = useState<boolean>(false);
  const [zoneMustDisplayFactual, setZoneMustDisplayFactual] = useState<boolean>(false);
  const [zoneMustDisplaySolution, setZoneMustDisplaySolution] = useState<boolean>(false);
  const [isZoneHoveredWithCard, setIsZoneHoveredWithCard] = useState<boolean>(false);

  const isDrag = useAppSelector(selectIsDrag);
  const notification = useAppSelector(selectNotificationGame);
  const zoneIdNotification = useAppSelector(selectNotificationGameZoneId);
  const nbCardsRemaining = useAppSelector(selectNbRemainingCardsInZone(zoneId));
  const currentProgression = useAppSelector(selectProgression);

  const [isCardsInZone, setCardsInZone] = useState<boolean>(false);

  // zone container className
  const [zoneContainerClass, setZoneContainerClass] = useState('zone__container');

  const handleDragStop = (e: DraggableEvent, data: Object) => {
    if (onDragStop) {
      onDragStop(zoneId, data, e);
    }
  };

  const factualOrSolutionToDisplay = () => {
    if (isProgression) {
      setZoneMustDisplayFactual(true);
      setZoneMustDisplaySolution(true);
      return;
    }
    if (!solutionAndFactualLotsIds) {
      return;
    }

    // define the type of the current lot (factual, solution or thematique)
    setIsCurrentLotFactual(currentLotId === solutionAndFactualLotsIds?.factual);
    setIsCurrentLotSolution(currentLotId === solutionAndFactualLotsIds?.solution);

    // if the card is factual, display nothing
    // if the card is solution or thematique, display the factual lot if there is a factual card in the zone and we are not editing the factual lot

    // if (
    //   (isCurrentLotSolution || (!isCurrentLotSolution && !isCurrentLotFactual)) &&
    //   lots[solutionAndFactualLotsIds?.factual] !== undefined && currentProgression?.factual
    // ) {
    //   setZoneMustDisplayFactual(true);
    // }

    // if the card is thematique, display the factual and/or solution lots if there is a factual and/or a solution card in the zone
    // if (currentProgression?.solution && !isCurrentLotFactual && !isCurrentLotSolution && lots[solutionAndFactualLotsIds?.solution] !== undefined) {
    //   setZoneMustDisplaySolution(true);
    // }

    // if the user is playing a game, we can't display the current lot cards
    if (isGame && isCurrentLotFactual) {
      setZoneMustDisplayFactual(false);
      setZoneMustDisplaySolution(false);
    }
    if (isGame && isCurrentLotSolution) {
      setZoneMustDisplaySolution(false);
    }
    // if we are editing the lot, we can't display the current lot cards
    if (isEditLot && isCurrentLotFactual) {
      setZoneMustDisplayFactual(false);
      setZoneMustDisplaySolution(false);
    }
    if (isEditLot && isCurrentLotSolution) {
      setZoneMustDisplaySolution(false);
    }
  };

  const zoneContainerClassDefinition = () => {
    let zoneContainerClass = 'zone__container-empty';
    if (!cardsInSlot && !zoneMustDisplayFactual && !zoneMustDisplaySolution) {
      zoneContainerClass = 'zone__container-empty';
    } else if (cardsInSlot && cardsInSlot.length === 1 && !zoneMustDisplayFactual && !zoneMustDisplaySolution) {
      zoneContainerClass = 'zone__container-card';
    } else if (cardsInSlot && cardsInSlot.length === 1 && (zoneMustDisplayFactual || zoneMustDisplaySolution)) {
      zoneContainerClass = 'zone__container-card';
    } else if (!cardsInSlot && (zoneMustDisplayFactual || zoneMustDisplaySolution)) {
      zoneContainerClass = 'zone__container-factual-solution';
    }

    setZoneContainerClass(zoneContainerClass);
  };

  useEffect(() => {
    factualOrSolutionToDisplay();
  });

  useEffect(() => {
    if (notification) {
      setTimeout(() => {
        dispatch(setNotificationGameZone(''));
        dispatch(setNotificationGameZoneId(''));
      }, 1500);
    }
  }, [notification]);

  // different use effect to manage the zoneContainerClassDefinition that must run at each change of content in the zone
  useEffect(() => {
    zoneContainerClassDefinition();
    cardsInZone();
  }, [cardsInSlot, zoneMustDisplayFactual, zoneMustDisplaySolution]);

  const popupCardsData = () => {
    if (!solutionAndFactualLotsIds && !cardsInSlot) {
      return;
    }

    let popupZoneData: {
      factual?: Card[];
      solution?: Card[];
      thematique?: Card[];
      lotType?: string;
      isGame?: boolean;
      textInfo?: string;
    } = {};

    // if (zoneMustDisplayFactual && zoneMustDisplaySolution && lots && solutionAndFactualLotsIds) {
    //   popupZoneData['factual'] = lots[solutionAndFactualLotsIds?.factual];
    //   popupZoneData['solution'] = lots[solutionAndFactualLotsIds?.solution];
    // } else if (zoneMustDisplayFactual && lots && solutionAndFactualLotsIds) {
    //   popupZoneData['factual'] = lots[solutionAndFactualLotsIds?.factual];
    // } else if (zoneMustDisplaySolution && lots && solutionAndFactualLotsIds) {
    //   popupZoneData['solution'] = lots[solutionAndFactualLotsIds?.solution];
    // }

    // add cardsinSlot array to popup data
    if (cardsInSlot) {
      switch (cardsInSlot[0]?.lotType) {
        case LotType.THEMATIQUE:
          popupZoneData['thematique'] = [...cardsInSlot];
          popupZoneData['lotType'] = LotType.THEMATIQUE;
          break;
        case LotType.FACTUEL:
          popupZoneData['factual'] = [...cardsInSlot];
          popupZoneData['lotType'] = LotType.FACTUEL;
          break;
        case LotType.SOLUTION:
          popupZoneData['solution'] = [...cardsInSlot];
          popupZoneData['lotType'] = LotType.SOLUTION;
          break;
      }
    }
    if (textInfo) {
      popupZoneData['textInfo'] = textInfo;
    }
    popupZoneData['isGame'] = isGame || isProgression;
    return popupZoneData;
  };

  // open the pop up to display the cards in the zone if the zone is clicked on (factual and/or solutions)
  function openPopUpDisplayCards() {
    const dataToDisplay = popupCardsData();
    if (dataToDisplay?.factual || dataToDisplay?.solution || dataToDisplay?.thematique) {
      dispatch(setPopupData(dataToDisplay));
      dispatch(setType('displayZoneCards'));
      // prevent blinking to happen
      dispatch(setCardsPopup(true));
    } else {
      return;
    }
  }

  function editZone() {
    dispatch(setZoneToEdit(zoneId));
    dispatch(setType('editZone'));
  }

  // Check if existing cards like factual and solution must be displayed in the zone when creating a map from admin
  const cardsInZone = (): void => {
    // if (!lots && !solutionAndFactualLotsIds && (!zoneMustDisplaySolution || !zoneMustDisplayFactual)) {
    //   setCardsInZone(false);
    // }

    const dataToDisplay = popupCardsData();

    // if we are in admin mode OR we are plating the game and the zone has no cards in it we display the fatual and solution data
    if (
      (dataToDisplay && !isGame) ||
      (dataToDisplay && isGame && (!cardsInSlot || cardsInSlot.length === 0)) ||
      isProgression
    ) {
      if (
        (dataToDisplay?.factual ? (dataToDisplay?.factual as Card[]).length : 0) +
          (dataToDisplay?.solution ? (dataToDisplay?.solution as Card[]).length : 0) >
        0
      ) {
        setCardsInZone(true);
      } else {
        setCardsInZone(false);
      }
    }
    // if we are playing the game and the zone has the right card on it, we only display the card and not the previous ones
    // if two of the lot are in, display the vignettes
    if (isGame && cardsInSlot) {
      setCardsInZone(true);
    }
    if (isTutorial && isGame && cardsInSlot && cardsInSlot.length > 0) {
      setCardsInZone(true);
    }
    if (isProgression) {
      setCardsInZone(true);
    }
  };

  // border class definition
  const [zoneBorderClass, setZoneBorderClass] = useState<string>('zone__container');
  useEffect(() => {
    if (isProgression) {
      return;
    }
    let borderClass = 'zone__container';
    if (cardsInSlot && cardsInSlot[0]) {
      borderClass +=
        ' border-solid' + (isCurrentLotFactual || isTutorial ? ' factual' : isCurrentLotSolution ? ' solution' : '');
    } else if (canReceiveCardFromLot) {
      borderClass +=
        ' receive' +
        (isCurrentLotSolution ? '-solution' : isCurrentLotFactual || isTutorial ? '-factual' : '-thematique');
    }

    if (!isCardsInZone && !canReceiveCardFromLot && isGame) {
      borderClass += ' hidden';
    } else if (isCardsInZone && !canReceiveCardFromLot && isGame) {
      borderClass += ' inside';
    }

    if (
      (isZoneHoveredWithCard && canReceiveCardFromLot && nbCardsRemaining! > 0) ||
      (!isGame && isZoneHoveredWithCard)
    ) {
      borderClass += ' card-hovered';
      borderClass +=
        ' receive' +
        (isCurrentLotSolution ? '-solution' : isCurrentLotFactual || isTutorial ? '-factual' : '-thematique');
    } else if (!isZoneHoveredWithCard) {
      borderClass = borderClass.replace(' card-hovered', '');
    }

    setZoneBorderClass(borderClass);
  }, [cardsInSlot, isZoneHoveredWithCard, cardsInZone]);

  // update the position of the zone based on the buffer and the position of the zone
  const [xPosition, setXPosition] = useState<number>(bufferX != 1 ? x * bufferX : x);
  const [yPosition, setYPosition] = useState<number>(bufferY != 1 ? y * bufferY : y);
  const [zoneWidth, setZoneWidth] = useState<number>(55);
  const [zoneHeight, setZoneHeight] = useState<number>(32);

  useEffect(
    () => {
      // edit positions of the zones based on the buffer
      setXPosition(bufferX != 1 ? x * bufferX : x);
      setYPosition(bufferY != 1 ? y * bufferY : y);

      // edit the size of the zones based on the buffer
      setZoneWidth(bufferX != 1 ? 55 * bufferX : 55);
      setZoneHeight(bufferY != 1 ? 37 * bufferY : 37);
    },
    isAdminEdit ? [bufferX, bufferY] : [bufferX, bufferY, x, y]
  );

  useEffect(() => {
    if (!isDrag) {
      setIsZoneHoveredWithCard(false);
    }
  }, [isDrag]);

  // Style of the entire zone
  const divStyle = {
    left: `${xPosition}px`,
    top: `${yPosition}px`,
    position: 'absolute',
    width: `${zoneWidth}px`,
    height: `${zoneHeight}px`,
    overflow: 'initial',
    cursor: isAdminEdit ? 'grab' : isCardsInZone || (cardsInSlot && cardsInSlot.length > 0) ? 'pointer' : 'auto'
  } as const;

  const renderSmallBox = (type: LotType, id: string) => {
    const smallBoxWidth = 7;
    const smallBoxHeight = 5;
    const smallBoxMarginTop = 1;
    let color = '';
    switch (type) {
      case LotType.FACTUEL:
        color = 'zone__pink';
        break;
      case LotType.SOLUTION:
        color = 'zone__blue';
        break;
      case LotType.THEMATIQUE:
        color = 'zone__yellow';
        break;
      default:
        return null;
    }
    return (
      <div
        key={id}
        style={{
          width: `${smallBoxWidth * bufferX}px`,
          height: `${smallBoxHeight * bufferY}px`,
          marginTop: `${smallBoxMarginTop * bufferX}px`
        }}
        className={`zone__small-box ${color}`}
      />
    );
  };

  const content = (
    <div
      className={zoneBorderClass}
      style={divStyle}
      ref={zoneRef}
      onClick={isCardsInZone || (cardsInSlot && cardsInSlot.length > 0) ? openPopUpDisplayCards : undefined}
      onMouseEnter={() => {
        isDrag && setIsZoneHoveredWithCard(true);
      }}
      onMouseLeave={() => {
        isDrag && setIsZoneHoveredWithCard(false);
      }}
    >
      {/* Used when dragged to add error or success message */}
      {notification && zoneIdNotification === zoneId && (
        <div className={`notification-zone ${notification}`}>
          <div>
            <img src={notification === 'success' ? CheckIcon : CloseIcon} alt="" />
            <p>
              Emplacement <b>{notification === 'success' ? 'correct' : 'incorrect'}</b>
            </p>
          </div>
        </div>
      )}

      {/* Used when admin is editing zones positions on the map */}
      {isAdminEdit && (
        <div onClick={() => editZone()} className="badge-zone">
          <img src={EditZoneIcon} alt="" />
        </div>
      )}

      {isDrag && !isGame && (
        <div
          className={`portal portal__${
            isCurrentLotFactual || isTutorial ? 'factual' : isCurrentLotSolution ? 'solution' : 'thematique'
          }`}
          data-id={zoneId}
        >
          Placer ici
        </div>
      )}

      {/* Used when drag is true to get the zone */}
      {isDrag && isGame && canReceiveCardFromLot && nbCardsRemaining! > 0 && zoneIdNotification !== zoneId && (
        <div
          className={`portal portal__${
            isCurrentLotFactual || isTutorial ? 'factual' : isCurrentLotSolution ? 'solution' : 'thematique'
          }`}
          data-id={zoneId}
        >
          Placer ici
        </div>
      )}

      <div className={zoneContainerClass}>
        <div className="smallbox">
          {/* Used when the zone has received card content that must be diplayed*/}
          {solutionAndFactualLotsIds &&
            !isCardsInZone &&
            cardsInSlot &&
            cardsInSlot.length === 1 &&
            cardsInSlot.map((card: Card) => card.lotType && renderSmallBox(card.lotType as LotType, card._id!))}

          {/*If user is in tutorial, we display the first card*/}
          {isGame &&
            isTutorial &&
            cardsInSlot &&
            cardsInSlot.length === 1 &&
            renderSmallBox(LotType.FACTUEL, cardsInSlot[0]._id!)}

          {/* Used when the zone has received more than one card content that must be diplayed*/}
          {solutionAndFactualLotsIds && isCardsInZone && (
            <>
              {zoneMustDisplayFactual && cardsInSlot?.map((card: Card) => renderSmallBox(LotType.FACTUEL, card._id!))}
              {zoneMustDisplaySolution && cardsInSlot?.map((card: Card) => renderSmallBox(LotType.SOLUTION, card._id!))}
              {cardsInSlot?.map((card: Card) => card.lotType && renderSmallBox(card.lotType as LotType, card._id!))}
            </>
          )}

          {isProgression &&
            cardsInSlot &&
            cardsInSlot.map((card: Card) => card.lotType && renderSmallBox(card.lotType as LotType, card._id!))}

          {/* Used when the zone has received multiplecard content that must be diplayed*/}
          {!isCardsInZone &&
            cardsInSlot &&
            cardsInSlot.length > 1 &&
            cardsInSlot.map((card: Card) => card.lotType && renderSmallBox(card.lotType as LotType, card._id!))}
        </div>

        <div className="nb">
          {isGame && canReceiveCardFromLot && typeof nbCardsRemaining === 'number' && (
            <div>
              <p
                className={`${
                  isCurrentLotFactual || isTutorial ? 'factual' : isCurrentLotSolution ? 'solution' : 'thematique'
                }${nbCardsRemaining === 0 ? ' grey' : ''}`}
              >
                Emplacement{nbCardsRemaining > 1 ? 's' : ''}
                <b>
                  restant : <span className={nbCardsRemaining === 0 ? 'darkgrey' : ''}>{nbCardsRemaining}</span>
                </b>
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
  // If the user is an admin, the zone can be dragged
  return isAdminEdit ? <Draggable onStop={handleDragStop}>{content}</Draggable> : content;
}
