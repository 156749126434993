import { Map as MapComponent } from 'views/components/base/map/map/map';
import { getZones } from 'services/zones/zonesService';
import { useEffect, useState } from 'react';
import { selectLot, setCreatedLot } from 'redux/slices/lotCreation';
import { useAppSelector } from 'redux/hooks';
import { useZoneRefs } from '../../../components/base/map/map/editZonesHooks';
import { CreateCardZonesControls } from './controls/createCardZonesControls';
import { Card, Map, ZonesTypeMap, Zone } from 'constants/index';
import { useDispatch } from 'react-redux';
import { selectZones, setZones, setZonesTypeMap } from 'redux/slices/zones';
import { setType, setPopupData } from 'redux/slices/popup';
import { setNotificationMessage, setNotificationType } from 'redux/slices/notification';
import { getMapById } from '../../../../services/maps/mapService';
import { updateCards } from '../../../../services/card/cardService';

export function CreateCardZones() {
  const dispatch = useDispatch();
  const [map, setMap] = useState<Map>();

  // Keep the ids of the factuel and solution lots to display them on the map if needed
  const [solutionAndFactualLotsIds, setSolutionAndFactualLotsIds] = useState<{
    solution: string;
    factual: string;
  }>();

  // Get the cards lots and zones from the redux store
  const zones = useAppSelector(selectZones);
  const lot = useAppSelector(selectLot);

  // Get the refs of the zones on the map
  const { zoneRefs } = useZoneRefs(zones);

  useEffect(() => {
    if (lot.mapId) {
      getMapById(lot.mapId).then(async (map: Map) => {
        const zoneData = await getZones();
        setSolutionAndFactualLotsIds(zoneData.solutionAndFactualLots);

        setMap(map);
        dispatch(setZones(map.zones));
        dispatch(setZonesTypeMap(ZonesTypeMap.creation));
      });
    }
  }, [lot.mapId]);

  const handleCardDragStop = (zoneId: string, card: Card): boolean => {
    const zone = zones.find((z: Zone) => z._id === zoneId);

    if (zone && lot._id) {
      const newCards = lot.cards.map((c: Card) => {
        if (c._id === card._id) {
          return { ...c, zoneId: zoneId };
        }
        return c;
      });
      dispatch(setCreatedLot({ ...lot, cards: newCards }));
      dispatch(setNotificationType('validation'));
      dispatch(setNotificationMessage('cardPlacedLot'));
      return true;
    } else {
      dispatch(setNotificationType('error'));
      dispatch(setNotificationMessage('cardNotPlacedInLot'));
      return false;
    }
  };

  // Save zones to the database, accessible from the map controls only if all cards were set on the map
  const saveZones = async () => {
    if (lot._id) {
      const saved = await updateCards(lot._id, lot.cards);
      if (saved) {
        // dispatch the popup action to open the popup for publication
        dispatch(setPopupData({ lot, isCreate: true }));
        dispatch(setType('publishLot'));
      }
    }
  };

  if (!map) return null;

  return (
    <MapComponent
      map={map}
      lotId={lot._id}
      cards={lot.cards}
      zoneRefs={zoneRefs}
      isAdminEdit={false}
      solutionAndFactualLotsIds={solutionAndFactualLotsIds}
      mapControls={
        <CreateCardZonesControls cards={lot.cards} saveZones={saveZones} onCardDragStop={handleCardDragStop} />
      }
    />
  );
}
