import './Inputs.scss';
import React, { useEffect } from 'react';
import closeIcon from 'assets/icons/close-icon.svg';
import greenTick from 'assets/icons/correct.png';
import redError from 'assets/icons/error-icon.svg';
import { CommonFileInputProps } from 'constants/index';
import { ReactComponent as FileIcon } from 'assets/icons/file-icon.svg';

export function CommonFileInput({
  isEdit,
  file,
  errorMessage,
  labelPlaceholder,
  labelClasses,
  setFileVariable,
  formatMessage,
  acceptedFormats,
  setValidity,
  fileWasChanged
}: CommonFileInputProps) {
  const [imageValidity, setImageValidity] = React.useState<string>('noInput');

  useEffect(() => {
    if (file) {
      checkValidity(file.name.split('.').pop() || '');
      setValidity(imageValidity !== 'noInput' && imageValidity !== 'inputError' ? true : false);
    } else if (imageValidity !== 'noInput' && imageValidity !== 'inputError') {
      setValidity(true);
    } else {
      setValidity(false);
    }
  }, [imageValidity, file]);

  const handleFileVariable = (file: File | undefined) => {
    if (file) {
      const fileNameLowercase = file.name.toLowerCase();
      const updatedFile = new File([file], fileNameLowercase, { type: file.type });
      setFileVariable(updatedFile);
    }
  };

  const handleCloseClick = () => {
    const input = document.getElementById('file-input-container__input') as HTMLInputElement;
    input.value = '';
    setImageValidity('noInput');
    handleFileVariable(undefined);
  };

  const changeHandlerFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null && event.target.files[0]) {
      const fileExtension = event.target.files[0].name.split('.').pop();
      checkValidity(fileExtension || '');
      handleFileVariable(event.target.files[0]);
      if (fileWasChanged) {
        fileWasChanged(true);
      }
    }
  };

  const checkValidity = (fileExtension: string) => {
    if (acceptedFormats?.includes(fileExtension)) {
      setImageValidity('inputValid');
    } else {
      setImageValidity('inputError');
    }
  };

  return (
    <div className={isEdit ? 'file-input-container edit-file' : 'file-input-container'}>
      <p className={labelClasses}>{labelPlaceholder}</p>
      <div className="file-input-container__elements">
        {imageValidity === 'inputValid' && (
          <img className="file-input-container__icons" src={greenTick} alt="validation icon" />
        )}
        {imageValidity === 'inputError' && (
          <img className="file-input-container__icons" src={redError} alt="error icon" />
        )}
        <input
          type="file"
          id="file-input-container__input"
          className={imageValidity}
          onChange={(e) => {
            changeHandlerFile(e);
          }}
        />
        <FileIcon className="file-input-container__file-icon" />
        <div className="file-input-container__filename">
          {file && <p>{file.name}</p>}
          {file && <img src={closeIcon} alt="delete image" onClick={handleCloseClick} />}
        </div>
        {imageValidity === 'inputError' && <p className="file-input-container__error-message">{errorMessage}</p>}
      </div>
      <p className="file-input-container__format-message">{formatMessage}</p>
    </div>
  );
}
