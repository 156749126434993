import { Lot, Video } from 'constants/index';
import { apiUtils } from 'services/utils';

export const createLot = async (formData: FormData) => {
  try {
    const lot = await apiUtils.apiPost(`${process.env.REACT_APP_API_URL}/lots`, formData);
    return lot;
  } catch (error) {
    console.log(error);
  }
};

export const getLotById = async (id: string) => {
  try {
    const lot = await apiUtils.apiGet(`${process.env.REACT_APP_API_URL}/lots/${id}`);
    return lot;
  } catch (error) {
    console.log(error);
  }
};

export const getAllLots = async () => {
  try {
    const lots = await apiUtils.apiGet(`${process.env.REACT_APP_API_URL}/lots`);
    return lots;
  } catch (error) {
    console.log(error);
  }
};

export const addLotToUser = async (
  id: string,
  email: string,
  nbErrors: number,
  nbAttempts: number,
  timeElapsed: number,
  timeElapsedOnVideos?: number[]
) => {
  try {
    const addedLotUser = await apiUtils.apiPut(`${process.env.REACT_APP_API_URL}/lots/${id}/addlotuser`, {
      email,
      nbErrors,
      nbAttempts,
      timeElapsed,
      timeElapsedOnVideos
    });
    return addedLotUser;
  } catch (error) {
    console.log(error);
  }
};

export const editLot = async (id: string, formData: Object) => {
  try {
    const editedLot = await apiUtils.apiPut(`${process.env.REACT_APP_API_URL}/lots/${id}`, formData);
    return editedLot;
  } catch (error) {
    console.log(error);
  }
};

export const publishLot = async (id: string) => {
  try {
    const publishedLot = await apiUtils.apiPut(`${process.env.REACT_APP_API_URL}/lots/${id}/publish`);
    return publishedLot;
  } catch (error) {
    console.log(error);
  }
};

export const unpublishLot = async (id: string) => {
  try {
    const unpublishedLot = await apiUtils.apiPut(`${process.env.REACT_APP_API_URL}/lots/${id}/unpublish`);
    return unpublishedLot;
  } catch (error) {
    console.log(error);
  }
};

export const saveLotOrder = async (lots: Lot[]) => {
  try {
    const lotIds = lots.map((lot) => lot._id);
    const savedLots = await apiUtils.apiPut(`${process.env.REACT_APP_API_URL}/lots/order`, lotIds);
    return savedLots;
  } catch (error) {
    console.log(error);
  }
};

export const getFactualLot = async () => {
  try {
    const lot = await apiUtils.apiGet(`${process.env.REACT_APP_API_URL}/lots/factual-lot`);
    return lot;
  } catch (error) {
    console.log(error);
  }
};

export const getLotTypes = async () => {
  try {
    const lotTypes = await apiUtils.apiGet(`${process.env.REACT_APP_API_URL}/lots/existing-lot-types`);
    return lotTypes;
  } catch (error) {
    console.log(error);
  }
};

export const checkLotTitle = async (title: string) => {
  try {
    const lotTitle = await apiUtils.apiGet(`${process.env.REACT_APP_API_URL}/lots/check-title/${title}`);
    return lotTitle;
  } catch (error) {
    console.log(error);
  }
};

export const editIntroLotVideos = async (videos: Video[]) => {
  try {
    const body = { videos: videos };
    const editedVideos = await apiUtils.apiPut(
      `${process.env.REACT_APP_API_URL}/lots/edit-introduction-lot-videos`,
      body
    );
    return editedVideos;
  } catch (error) {
    console.log(error);
  }
};

export const getIntroLot = async () => {
  try {
    const introLot = await apiUtils.apiGet(`${process.env.REACT_APP_API_URL}/lots/introduction-lot`);
    return introLot;
  } catch (error) {
    console.log(error);
  }
};

export const deleteLot = async (id: string) => {
  try {
    const deleteLot = await apiUtils.apiDelete(`${process.env.REACT_APP_API_URL}/lots/${id}`);
    return deleteLot;
  } catch (error) {
    console.log(error);
  }
};