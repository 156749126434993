import './genericLotForm.scss';
import React from 'react';
import { CommonTextInput } from 'views/components/base/inputs/TextInput';
import { CommonFileInput } from 'views/components/base/inputs/FileInput';
import { Button } from 'views/buttons/base';
import { ReactComponent as RightArrow } from 'assets/icons/arrow-right-icon.svg';
import { Positions, LotType as LotTypeEnum, LotTypeObject } from 'constants/index';
import { useTranslation } from 'react-i18next';

interface GenericFormProps {
  isEdit: boolean | undefined;
  titleInfo: {
    onChange: (value: string) => void;
    setValidity: (isValid: boolean) => void;
    placeholder?: string | undefined | null;
    existingValue?: string;
    setEditLotTitleWasChanged?: (hasChanged: boolean) => void;
  };
  timeToCompleteInfo: {
    onChange: (value: string) => void;
    setValidity: (isValid: boolean) => void;
    placeholder?: string | undefined | null;
    existingValue?: string;
    setEditLotTimeToCompleteWasChanged?: (hasChanged: boolean) => void;
  };
  imageInfo: {
    file: File | undefined;
    setValidity: (isValid: boolean) => void;
    setFileVariable: (file: File | undefined) => void;
    placeholder?: string | undefined | null;
    lotImageWasChanged?: (hasChanged: boolean) => void;
  };
  badgeInfo: {
    file: File | undefined;
    setValidity: (isValid: boolean) => void;
    setFileVariable: (file: File | undefined) => void;
    placeholder?: string | undefined | null;
    lotBadgeWasChanged?: (hasChanged: boolean) => void;
  };
  submitLot: () => void;
  lotType?: LotTypeEnum;
  onLotTypeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  existingLots: LotTypeObject | null;
  isSubmitDisabled?: boolean;
  editLotSelected: { label: string; value: string } | null;
}

export function GenericLotForm({
  isEdit,
  titleInfo,
  timeToCompleteInfo,
  imageInfo,
  badgeInfo,
  submitLot,
  lotType,
  onLotTypeChange,
  existingLots,
  isSubmitDisabled,
  editLotSelected
}: GenericFormProps) {
  const { t } = useTranslation(['createLot']);
  const maxTitleLength = 30;
  const maxTimeToCompleteLength = 99;

  return (
    <div className="lot-form">
      <div className="lot-form__text-inputs">
        {/* NOM */}
        <CommonTextInput
          isEdit={isEdit}
          onChange={titleInfo.onChange}
          inputPlaceholder={titleInfo.placeholder ? titleInfo.placeholder : t('lotPlaceholder')}
          labelPlaceholder={t('lotName')}
          labelClasses="lot-form__input-label"
          errorMessage={t('titleError')}
          validationRules={{ isRequired: true, maxLength: maxTitleLength, minLength: 0 }}
          existingValue={titleInfo.existingValue ? titleInfo.existingValue : undefined}
          isDisabled={isEdit === true && titleInfo.existingValue === undefined}
          setValidity={titleInfo.setValidity}
          textWasChanged={titleInfo.setEditLotTitleWasChanged}
        />
        {/* Duree */}
        <CommonTextInput
          isEdit={isEdit}
          onChange={timeToCompleteInfo.onChange}
          inputPlaceholder={timeToCompleteInfo.placeholder || t('lotTimeToCompletePlaceholder')}
          labelPlaceholder={t('lotTimeToComplete')}
          labelClasses="lot-form__input-label"
          errorMessage={t('timeToCompleteError')}
          validationRules={{ isNumber: true, maxNumber: maxTimeToCompleteLength, isInteger: true }}
          existingValue={timeToCompleteInfo.existingValue ? timeToCompleteInfo.existingValue : undefined}
          isDisabled={isEdit === true && timeToCompleteInfo.existingValue === undefined}
          setValidity={timeToCompleteInfo.setValidity}
          textWasChanged={timeToCompleteInfo.setEditLotTimeToCompleteWasChanged}
        />
      </div>
      {/* Image */}
      <div className="lot-form__file-inputs">
        <CommonFileInput
          isEdit={isEdit}
          file={imageInfo.file}
          setFileVariable={imageInfo.setFileVariable}
          errorMessage={t('imageError')}
          labelPlaceholder={t('lotImage')}
          labelClasses="lot-form__input-label"
          formatMessage={t('lotFileMessage')}
          acceptedFormats={['png', 'jpg', 'jpeg', 'svg']}
          setValidity={imageInfo.setValidity}
          fileWasChanged={imageInfo.lotImageWasChanged}
        />
        {/* Badge */}
        <CommonFileInput
          isEdit={isEdit}
          file={badgeInfo.file}
          setFileVariable={badgeInfo.setFileVariable}
          errorMessage={t('badgeError')}
          labelPlaceholder={t('lotBadge')}
          labelClasses="lot-form__input-label"
          formatMessage={t('lotFileMessage')}
          acceptedFormats={['png', 'jpg', 'jpeg', 'svg']}
          setValidity={badgeInfo.setValidity}
          fileWasChanged={badgeInfo.lotBadgeWasChanged}
        />
      </div>
      <div className="lot-form__radio-group">
        <label className="custom-radio">
          <input
            type="radio"
            value={LotTypeEnum.INTRODUCTION}
            checked={lotType === LotTypeEnum.INTRODUCTION}
            onChange={onLotTypeChange}
            disabled={!existingLots || existingLots.introduction || editLotSelected === null}
          />
          <span className="radio"></span>
          <span className="label-text">{t('lotIntroduction')}</span>
        </label>
        <label className="custom-radio">
          <input
            type="radio"
            value={LotTypeEnum.FACTUEL}
            checked={lotType === LotTypeEnum.FACTUEL}
            onChange={onLotTypeChange}
            disabled={!existingLots || existingLots.factuel || editLotSelected === null}
          />
          <span className="radio"></span>
          <span className="label-text">{t('lotFactuel')}</span>
        </label>
        <label className="custom-radio">
          <input
            type="radio"
            value={LotTypeEnum.SOLUTION}
            checked={lotType === LotTypeEnum.SOLUTION}
            onChange={onLotTypeChange}
            disabled={!existingLots || existingLots.solution || editLotSelected === null}
          />
          <span className="radio"></span>
          <span className="label-text">{t('lotSolution')}</span>
        </label>
        <label className="custom-radio">
          <input
            type="radio"
            value={LotTypeEnum.THEMATIQUE}
            checked={lotType === LotTypeEnum.THEMATIQUE}
            onChange={onLotTypeChange}
            disabled={editLotSelected === null}
          />
          <span className="radio"></span>
          <span className="label-text">{t('lotThematique')}</span>
        </label>
      </div>

      <div className="lot-form__buttons">
        <Button
          label="next"
          type="submit"
          classType={`${isSubmitDisabled ? 'off' : 'primary'}-button-lg`}
          translation="createLot"
          onClick={submitLot}
          Icon={{
            Svg: RightArrow,
            position: Positions.END
          }}
        />
      </div>
    </div>
  );
}
