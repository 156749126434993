// import card enum
import './gameMapControls.scss';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { Button } from 'views/buttons/base';
import { Card as CardComponent } from 'views/components/base/cards/Card';
import { Card, CardType, CheckedModule, GameMapControlsProps, Positions } from 'constants/index';
import { useAppSelector } from 'redux/hooks';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { ReactComponent as RightArrow } from 'assets/icons/arrow-right-icon.svg';
import { ReactComponent as LeftArrow } from 'assets/icons/arrow-left-icon.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/eye-icon.svg';
import { ReactComponent as ZoomIcon } from 'assets/icons/zoom-icon.svg';
import {
  selectCurrentCardIdShown,
  selectCardsIdsLeftToPlace,
  selectLot,
  moveToNextOrPreviousCard,
  setStepToBadge,
  setIsDrag,
  selectIsDrag,
  selectCurrentCard,
  selectIsBlinking,
  setBlinking,
  selectIsCardsPopupOpen
} from 'redux/slices/game';
import { addLotToUser } from 'services/lot/lotService';
import { selectEmail } from 'redux/slices/user';
import { useTranslation } from 'react-i18next';
import { setPopupData, setType } from 'redux/slices/popup';
import { getFile } from 'services/file/fileService';
import { selectSector } from 'redux/slices/manageSectors';

export function GameMapControls({
  cards,
  onCardDragStop,
  isCurrentLotFactualOrSolution,
  nbErrors,
  nbAttempts
}: GameMapControlsProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation(['game']);
  const currentLot = useAppSelector(selectLot);
  const currentDisplayedCardId: string = useAppSelector(selectCurrentCardIdShown);
  const currentDisplayedCard: Card = useAppSelector(selectCurrentCard);
  const cardsLeftToPlace = useAppSelector(selectCardsIdsLeftToPlace);
  const userEmail = useAppSelector(selectEmail);
  const isDrag = useAppSelector(selectIsDrag);
  const isBlinking = useAppSelector(selectIsBlinking);
  const isCardsPopupOpen = useAppSelector(selectIsCardsPopupOpen);
  const sector = useAppSelector(selectSector);

  const [link, setLink] = useState<string[]>([]);
  const [draggableCardPosition, setDraggableCardPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [shrinkCard, setShrinkCard] = useState<boolean>(false);

  const [timer, setTimer] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => setTimer((timer) => timer + 1), 1000);
    return () => {
      clearInterval(interval);
      dispatch(setBlinking(false));
    };
  }, []);

  useEffect(() => {
    const blinkTriggerTimer = setTimeout(() => {
      if (isCardsPopupOpen) {
        dispatch(setBlinking(false));
      } else {
        dispatch(setBlinking(true));
      }
    }, 10000);

    return () => {
      clearTimeout(blinkTriggerTimer);
    };
  }, [isBlinking]);

  useEffect(() => {
    if (currentDisplayedCard) {
      getLink(currentDisplayedCard);
    }
  }, [currentDisplayedCard]);

  // display next card from array
  const nextCard = () => {
    dispatch(moveToNextOrPreviousCard('next'));
    dispatch(setBlinking(false));
  };

  // display previous card from array
  const previousCard = () => {
    dispatch(moveToNextOrPreviousCard('previous'));
    dispatch(setBlinking(false));
  };

  const getLink = (card: Card) => {
    // eslint-disable-next-line
    let regex =
      /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/gim;
    if (card.content) {
      let match = [...new Set(card.content.match(regex))];
      if (match.length) {
        match = match.map((m) => (m.includes('http') ? m : `http://${m}`));
        if (match.length > 3) {
          setLink([...match.slice(0, 3)]);
        } else {
          setLink([...match]);
        }
      } else {
        setLink([]);
      }
    } else {
      setLink([]);
    }
  };

  const submitLot = async () => {
    if (currentLot._id) {
      const submit = await addLotToUser(currentLot._id, userEmail, nbErrors, nbAttempts, timer);
      if (submit) {
        dispatch(setStepToBadge());
      }
    }
  };
  const moveToCursor = (e: any, el: DraggableData) => {
    let box = el.node.getBoundingClientRect();
    let mouse_top = e.clientY;
    let mouse_left = e.clientX;
    let diff_x = mouse_left - box.left;
    let diff_y = mouse_top - box.top;
    el.node.style.top = Number(el.node.style.top.replace('px', '')) - 1 + diff_y + 'px';
    el.node.style.left = Number(el.node.style.left.replace('px', '')) - 1 + diff_x + 'px';
  };

  const openImageZoom = async (card: Card) => {
    // TODO: setup static image instead of generating
    // image url from blob
    const blob = await getFile(card.imageUrl as string);

    if (blob) {
      const imageUrl = URL.createObjectURL(blob);
      // Open zoom image details popup
      dispatch(setType('zoom-image'));
      // Pass data to popup
      dispatch(setPopupData({ imageUrl: imageUrl }));
    }
  };

  return (
    <div className="game-map-controls__container">
      <p className="title">{currentLot.title}</p>
      {cards.map((card, index) => (
        <div
          key={index}
          style={card._id === currentDisplayedCardId ? {} : { display: 'none' }}
          className={
            isDrag && isCurrentLotFactualOrSolution
              ? `game-map-controls__no-card-border${isCurrentLotFactualOrSolution} isDragged`
              : ''
          }
        >
          <Draggable
            position={draggableCardPosition}
            defaultClassName="relative"
            onStart={(e, el) => {
              moveToCursor(e, el);
              dispatch(setIsDrag(true));
            }}
            onStop={(e, el) => {
              el.node.style.top = '0px';
              el.node.style.left = '0px';
              setShrinkCard(false);
              dispatch(setIsDrag(false));
              const draggableElement = e.target as HTMLElement;
              if (draggableElement && onCardDragStop) {
                const zoneId = draggableElement.getAttribute('data-id');
                if (zoneId) {
                  const hasBeenDroppedOnZone = onCardDragStop(zoneId, card);
                  if (hasBeenDroppedOnZone) {
                    // if the card was dropped on a zone, replace the draggable element at the original position for the next card
                    setDraggableCardPosition({ x: 0, y: 0 });
                    dispatch(setBlinking(false));
                  }
                }
              }
            }}
            onDrag={(e: DraggableEvent, positionData) => {
              if (positionData.x < -50) {
                setShrinkCard(true);
                dispatch(setBlinking(false));
              }
            }}
          >
            <div className={`game-map-controls__card-container disable-link ${!isDrag ? 'flex-display' : ''}`}>
              <CardComponent cardType={CardType.map} card={card} shrinkCard={shrinkCard} />
            </div>
          </Draggable>
        </div>
      ))}
      {currentDisplayedCard &&
        currentDisplayedCard.moduleType !== CheckedModule.text &&
        cardsLeftToPlace.length > 0 && (
          <div className="game-map-controls__informations">
            {currentDisplayedCard.imageUrl && (
              <div
                tabIndex={1}
                onClick={() => {
                  openImageZoom(currentDisplayedCard);
                }}
              >
                <span>{t('zoomImage')}</span>
                <ZoomIcon />
              </div>
            )}
            {link && link[0] && (
              <div tabIndex={2}>
                <a href={link[0]} target="_blank">
                  {t('previewLink')}
                </a>
                <EyeIcon />
              </div>
            )}
          </div>
        )}
      {currentDisplayedCard &&
        currentDisplayedCard.moduleType === CheckedModule.text &&
        cardsLeftToPlace.length > 0 && (
          <div className={`game-map-controls__informations ${link.length > 1 ? 'full-text' : ''}`}>
            {link.length === 1 && (
              <div tabIndex={1}>
                <a href={link[0]} target="_blank">
                  {t('previewLink')}
                </a>
                <EyeIcon />
              </div>
            )}
            {link.length > 1 &&
              link.map((link, index) => {
                return (
                  <div tabIndex={index + 1}>
                    <a href={link} target="_blank">
                      {index === 0 ? t('previewLink') : 'Lien'} {index + 1}
                    </a>
                    <EyeIcon />
                  </div>
                );
              })}
          </div>
        )}
      <div>
        <div className="game-map-controls__progress-bar">
          <div
            className="game-map-controls__progress-bar-fill"
            style={{ width: `${((cards.length - cardsLeftToPlace.length) / cards.length) * 100}%` }}
          ></div>
        </div>
      </div>
      <span className="game-map-controls__card-not-set">
        <p>
          <span>
            {cardsLeftToPlace.length > 0 ? cards.length - cardsLeftToPlace.length : cards.length} / {cards.length}
          </span>{' '}
          {t('cardsToSet')}
        </p>
      </span>
      <div className="game-map-controls__buttons">
        {cardsLeftToPlace.length > 0 && (
          <>
            {cardsLeftToPlace.indexOf(currentDisplayedCardId) > 0 && (
              <Button
                label="Carte précédente"
                type="button"
                classType="secondary-button-lg game"
                translation="createCard"
                onClick={previousCard}
                Icon={{
                  Svg: LeftArrow,
                  position: Positions.START
                }}
              />
            )}
            <Button
              label="Carte Suivante"
              type="button"
              classType={
                cardsLeftToPlace.indexOf(currentDisplayedCardId) === cardsLeftToPlace.length - 1
                  ? 'off-button-lg game'
                  : 'primary-button-lg game'
              }
              translation="createCard"
              onClick={nextCard}
              Icon={{
                Svg: RightArrow,
                position: Positions.END
              }}
            />
          </>
        )}
        {cardsLeftToPlace.length == 0 && (
          <Button
            label="Soumettre"
            type="button"
            classType={'primary-button-lg'}
            translation="createCard"
            onClick={submitLot}
          />
        )}
      </div>
    </div>
  );
}
