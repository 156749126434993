import './Lot.scss';
import { MouseEventHandler, PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { Button } from 'views/buttons/base';
import { Lot as LotType } from 'constants/index';
import MinusIcon from 'assets/icons/moins.svg';
import PlusIcon from 'assets/icons/plus.svg';
import LockIcon from 'assets/icons/lock-icon.svg';
import { changeLotsOrder } from 'redux/slices/manageLots';
import { useAppDispatch } from 'redux/hooks';
import { setType } from 'redux/slices/popup';

export function Lot({
  lot,
  buttonLabel,
  buttonAction,
  isBlocked,
  isLocked,
  props,
  isManageLot,
  isFirstLot,
  isLastLot
}: {
  lot: LotType;
  buttonLabel: string;
  buttonAction: MouseEventHandler<HTMLButtonElement>;
  isBlocked?: boolean;
  isLocked?: boolean;
  props?: PropsWithChildren<ReactElement>;
  isManageLot?: boolean;
  isFirstLot?: boolean;
  isLastLot?: boolean;
}) {
  const dispatch = useAppDispatch();
  const [imageSrc, setImageSrc] = useState<string | undefined>();

  useEffect(() => {
    setImageSrc(lot.imageUrl);

    return () => {
      if (imageSrc) {
        URL.revokeObjectURL(imageSrc);
      }
    };
  }, [lot.imageUrl]);

  const changePosition = (lot: LotType, action: string) => () => {
    if (lot._id) {
      dispatch(changeLotsOrder({ lotId: lot._id, action }));
    }
  };

  const result = (
    <div
      onClick={() => (isLocked ? dispatch(setType('lockedLot')) : false)}
      className={
        isBlocked !== undefined && isBlocked
          ? 'container-lot off'
          : isManageLot !== undefined && isManageLot
          ? 'container-lot container-lot__manage-lot'
          : isLocked !== undefined && isLocked
          ? 'container-lot locked'
          : 'container-lot'
      }
    >
      {isLocked && <img src={LockIcon} alt="lock" className="container-lot__image" />}
      <div className={!isLocked ? lot.theme : `${lot.theme} blank`}>
        <img className="container-lot__image" src={imageSrc} alt={lot.title} />
        <p className="container-lot__title">{lot.title}</p>
        {props}
        <div className={isManageLot ? 'container-lot__manage-buttons' : 'container-lot__button'}>
          {isManageLot && (
            <div className="container-lot__ordre-management">
              <p>Position: </p>
              {!isFirstLot && <img src={MinusIcon} alt="moins" onClick={changePosition(lot, 'minus')} />}
              {!isLastLot && <img src={PlusIcon} alt="plus" onClick={changePosition(lot, 'plus')} />}
            </div>
          )}
          <Button
            label={buttonLabel}
            type="button"
            classType="primary-button-md"
            translation="lot"
            onClick={buttonAction}
          />
        </div>
      </div>
    </div>
  );

  return result;
}
