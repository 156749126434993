const richTextTransform = {
  richTextToHtml(text: string): string {
    const html = text
      .replace(/\r?\n/g, '<br>')
      .replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
      .replace(/\*(.*?)\*/g, '<i>$1</i>')
      .replace(/__(.*?)__/g, '<u>$1</u>')
      .replace(/(https?:\/\/[^\s<]+)/g, '<a href="$1" target="_blank">$1</a>');
    return html;
  },

  htmlToRichText(html: string): string {
    const richText = html
      .replace(/r?<br>/g, '\n')
      .replace(/<b>(.*?)<\/b>/g, '**$1**')
      .replace(/<i>(.*?)<\/i>/g, '*$1*')
      .replace(/<u>(.*?)<\/u>/g, '__$1__')
      .replace(/(<a [^>]*href="(https?:[^>^"]*)"[^>]*>.+\/a>)/gim, '$2');
    return richText;
  }
};

export default richTextTransform;
