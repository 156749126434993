export function sanitizeFileName(fileName: string): string {
  // Split the file name into name and extension
  const fileParts = fileName.split('.');
  const fileExtension = fileParts.pop();
  const fileBaseName = fileParts.join('.');

  // Sanitize the file name part
  const sanitizedBaseName = fileBaseName
    .replace(/[^a-z0-9-_]/gi, '_') // Replace special characters and spaces with underscores
    .toLowerCase(); // Convert the file name to lowercase

  // Reassemble the sanitized file name with the extension
  const sanitizedFileName = `${sanitizedBaseName}.${fileExtension}`;
  return sanitizedFileName;
}
