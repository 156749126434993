import { apiUtils } from 'services/utils';
import { sanitizeFileName } from 'services/utils/sanitizeFileName';
import JSZip from 'jszip';

export const addFile = async (file: File | undefined) => {
  try {
    if (!file) {
      return undefined;
    }
    const formData = new FormData();
    const sanitizedFileName = sanitizeFileName(file.name);

    const sanitizedFile = new File([file], sanitizedFileName, { type: file.type });

    formData.append('image', sanitizedFile);

    const uploadedFile = await apiUtils.apiPost(`${process.env.REACT_APP_API_URL}/uploads`, formData);

    return uploadedFile;
  } catch (error) {
    console.log(error);
  }
};

export const getFile = async (fileUrl: any): Promise<Blob | undefined | void> => {
  try {
    if (!fileUrl) {
      return undefined;
    }
    const encodedFileURL = encodeURIComponent(fileUrl);
    const fetchedResponse: Response = await apiUtils.apiGet(
      `${process.env.REACT_APP_API_URL}/uploads/${encodedFileURL}`
    );
    const blob = await fetchedResponse.blob();
    const correctBlob = new Blob([blob], { type: fileUrl.includes('.svg') ? 'image/svg+xml' : 'image/jpeg' });
    return correctBlob;
  } catch (error) {
    console.log(error);
  }
};

export const getFiles = async (filesUrl: any): Promise<any> => {
  const imagesUrls: string[] = [];
  const regex = /\/([^/]+)$/;
  try {
    if (!filesUrl) {
      return undefined;
    }
    filesUrl.map((fileUrl: { itemImageUrl: string }) => {
      const match = regex.exec(decodeURIComponent(fileUrl.itemImageUrl));
      const imageName = match ? match[1] : '';
      const encodedFileURL = encodeURIComponent(imageName);
      imagesUrls.push(encodedFileURL);
    });
    const fetchedResponseGroup: Response = await apiUtils.apiPost(
      `${process.env.REACT_APP_API_URL}/uploads/multiple-files`,
      { imagesUrls }
    );
    const zip = new JSZip();
    const loadedZip = await zip.loadAsync(fetchedResponseGroup.blob());

    // Extract the files from the zip
    const fileNames = Object.keys(loadedZip.files);
    const blobs: Blob[] = [];
    const fileMap: { [key: string]: Blob } = {};

    // Iterate over each file and convert it to a blob
    for (const fileName of fileNames) {
      const file = loadedZip.files[fileName];
      const image = await file.async('blob');
      const blob = new Blob([image], { type: fileName.includes('.svg') ? 'image/svg+xml' : 'image/jpeg' });
      fileMap[fileName] = blob;

      blobs.push(blob);
    }
    return fileMap;
  } catch (error) {
    console.log(error);
  }
};

export const deleteFile = async (fileUrl: string) => {
  try {
    const deletedFile = await apiUtils.apiDelete(`${process.env.REACT_APP_API_URL}/uploads/${fileUrl}`);
    return deletedFile;
  } catch (error) {
    console.log(error);
  }
};
