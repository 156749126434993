import './manageLots.scss';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useEffect } from 'react';
import { Lot as LotType } from 'constants/index';
import { getAllLots, saveLotOrder } from 'services/lot/lotService';
import { Lot } from 'views/lots';
import { setType, setPopupData } from 'redux/slices/popup';
import { setLots, selectLots } from 'redux/slices/manageLots';
import { setNotificationType, setNotificationMessage } from 'redux/slices/notification';
import { getFiles } from 'services/file/fileService';

export function ManageLots() {
  const dispatch = useAppDispatch();

  const { t } = useTranslation(['manageLots']);
  const lots = useAppSelector(selectLots);

  useEffect(() => {
    const getLots = async () => {
      await getAllLots().then(async (values: LotType[]) => {
        if (values) {
          const images = values.map(({ _id, imageUrl }) => ({
            itemId: _id,
            itemImageUrl: imageUrl
          }));
          const mapBlob = await getFiles(images);
          const lotsWithImages = values.map((lot) => {
            const url = lot.imageUrl.substring(lot.imageUrl.lastIndexOf('/') + 1);
            if (Object.values(mapBlob).length > 0 && mapBlob[url]) {
              lot = {
                ...lot,
                imageUrl: (lot.imageUrl = URL.createObjectURL(mapBlob[url]))
              };
            } else {
              console.log('image impossible à afficher pour le lot :', lot.title);
            }
            return lot;
          });
          dispatch(setLots(lotsWithImages));
        }
      });
    };
    getLots();
  }, []);

  // Publish or unpublish lots
  function lotPublicationAction(lot: LotType) {
    return () => {
      dispatch(setPopupData({ lot, isCreate: false }));
      switch (lot.status) {
        case 'unpublished':
          dispatch(setType('publishLot'));
          break;
        case 'published':
          dispatch(setType('unpublishLot'));
          break;
        case 'unfinished':
          dispatch(setType('continueEditLot'));
          break;
        default:
          console.log('Publish lot');
      }
    };
  }

  function setButtonLabel(lot: LotType) {
    let buttonLabel;
    switch (lot.status) {
      case 'unpublished':
        buttonLabel = 'Publier';
        break;
      case 'published':
        buttonLabel = 'Dépublier';
        break;
      case 'unfinished':
        buttonLabel = 'Poursuivre';
        break;
      default:
        buttonLabel = 'Poursuivre';
    }
    return buttonLabel;
  }

  async function saveLotsOrder() {
    const saveLots = await saveLotOrder(lots);
    if (saveLots) {
      dispatch(setNotificationType('validation'));
      dispatch(setNotificationMessage('La position des lots a bien été enregistrée'));
    }
  }

  return (
    <>
      <button className="save-button GoButton" onClick={saveLotsOrder}>
        {' '}
        {t('save')}
      </button>
      <div className="manage-lots">
        <div className="manage-lots__grid">
          {[...lots].map((lot: LotType) => {
            return (
              <Lot
                key={`${lot._id}-${lot.order}`}
                lot={lot}
                buttonLabel={setButtonLabel(lot)}
                buttonAction={lotPublicationAction(lot)}
                isManageLot={true}
                isFirstLot={lot.order === 0}
                isLastLot={lot.order === lots.length - 1}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}
