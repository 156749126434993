import { ReactComponent as RightArrow } from 'assets/icons/arrow-right-icon.svg';
import { ManageStep } from 'constants/enums';
import { Positions, Sector } from 'constants/index';
import { t } from 'i18next';
import { useEffect } from 'react';
import { ActionMeta } from 'react-select';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  emptySector,
  goTo,
  selectCurrentPage,
  selectSector,
  selectSectors,
  setSector,
  setSectors
} from 'redux/slices/manageSectors';
import { getAllSectors } from 'services/sectors/sectorService';
import { Button } from 'views/buttons/base';
import { DropDownInput } from 'views/components/base/inputs/DropDownInput';
import customDropDownStyles from '../editLot/editLotForm/customDropDownStyles';
import { DetailSteps } from './detailSteps/detailSteps';
import './manageSteps.scss';

export function ManageSteps() {
  const dispatch = useAppDispatch();

  const sectors = useAppSelector(selectSectors);
  const sector = useAppSelector(selectSector);
  const page = useAppSelector(selectCurrentPage);

  const getSectors = async () => {
    const sectors = await getAllSectors();
    dispatch(setSectors(sectors));
  };
  async function handleSelectChange(sector: unknown, actionMeta: ActionMeta<unknown>) {
    dispatch(setSector(sector as Sector));
  }
  function goNext() {
    dispatch(goTo(ManageStep.detail));
  }
  useEffect(() => {
    // Retrieve sectors
    getSectors();

    return () => {
      dispatch(emptySector());
      dispatch(goTo(ManageStep.sector));
    };
  }, []);

  return (
    <>
      {page === ManageStep.sector && (
        <div className="">
          <div>
            <DropDownInput
              key={sectors.length}
              options={sectors}
              placeholder={t('Choisir un secteur / métier')}
              styles={customDropDownStyles}
              onChange={handleSelectChange}
              value={sector}
              defaultValue={null}
            />
          </div>
          <div className="btn-go-step">
            <Button
              label="Suivant"
              type="submit"
              disabled={!sector}
              classType={'primary-button-lg'}
              translation=""
              onClick={goNext}
              Icon={{
                Svg: RightArrow,
                position: Positions.END
              }}
            />
          </div>
        </div>
      )}
      {page === ManageStep.detail && <DetailSteps />}
    </>
  );
}
