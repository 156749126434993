import { Card, CheckedModule } from 'constants/index';
import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'redux/hooks';
import { selectEditCard } from 'redux/slices/lotEdition';
import richTextTransform from 'services/utils/richTextTransform';
import '../CardPopup.scss';
import './EditCardPopup.scss';

export function EditCardPopUp({
  ButtonsContent,
  blobImageSrc,
  moduleType,
  onImageChange,
  setImage,
  setModuleType,
  setText,
  text
}: {
  ButtonsContent: ReactNode;
  blobImageSrc: string | undefined;
  moduleType: CheckedModule | undefined;
  onImageChange: Function;
  setImage: Dispatch<SetStateAction<File | undefined>>;
  setModuleType: Dispatch<SetStateAction<CheckedModule | undefined>>;
  setText: Dispatch<SetStateAction<string | undefined>>;
  text: string;
}) {
  const card: Card = useAppSelector(selectEditCard);

  const { t } = useTranslation(['popup']);

  const [previewImage, setPreviewImage] = useState<string>('');

  useEffect(() => {
    let transformedText: string = '';

    if (blobImageSrc) {
      setPreviewImage(blobImageSrc);
    }

    if (card?.moduleType) {
      setModuleType(card.moduleType);
    }

    if (card.content) {
      transformedText = richTextTransform.htmlToRichText(card.content);
    }

    if (transformedText) {
      setText(transformedText);
    }
  }, []);

  const EditTextContent = (
    <div className={'card-popup__content text'}>
      <textarea
        className="card-popup__textarea"
        placeholder={t('addTextPlaceholder') || ''}
        value={text}
        onChange={(e) => {
          setText(e.target.value);
        }}
      />
    </div>
  );

  const EditImageContent = (
    <div className="card-popup__content image">
      <input
        id="popup-file"
        type="file"
        onChange={(e: any) => {
          onImageChange(e, (f: File) => {
            setImage(f);
            setPreviewImage(URL.createObjectURL(f));
          });
        }}
      />
      {previewImage ? <img src={previewImage} className="card-popup__image-uploaded" alt="preview image" /> : <></>}
    </div>
  );

  const EditTextAndImageContent = (
    <div className="card-popup__textimage">
      <div className="card-popup__input">
        <input
          type="text"
          value={text}
          placeholder={t('addTextPlaceholder') || ''}
          onChange={(e) => {
            setText(e.target.value);
          }}
        />
      </div>
      <div className="card-popup__imagecontainer">
        <input
          id="popup-file-text-image"
          type="file"
          onChange={(e: any) => {
            onImageChange(e, (f: File) => {
              setImage(f);
              setPreviewImage(URL.createObjectURL(f));
            });
          }}
        />
        {previewImage !== '' ? (
          <img src={previewImage} className="card-popup__textarea-image__image-uploaded" alt="preview image" />
        ) : (
          <></>
        )}
      </div>
    </div>
  );

  return (
    <div className="card-popup">
      <div className="card-popup__container">
        <div className={'card-popup__module card-popup__' + card.lotType}>
          {moduleType === CheckedModule.text ? EditTextContent : <></>}
          {moduleType === CheckedModule.textAndImage ? EditTextAndImageContent : <></>}
          {moduleType === CheckedModule.image ? EditImageContent : <></>}
        </div>
        {ButtonsContent}
      </div>
    </div>
  );
}
