import { Map } from 'constants/index';
import { apiUtils } from 'services/utils';

export const createMap = async (mapInfo: Map) => {
  try {
    const map = await apiUtils.apiPost(`${process.env.REACT_APP_API_URL}/maps`, mapInfo);
    return map;
  } catch (error) {
    console.log(error);
  }
};

export const getMapById = async (id: Exclude<Map['_id'], undefined>) => {
  try {
    const map = await apiUtils.apiGet(`${process.env.REACT_APP_API_URL}/maps/${id}`);
    return map;
  } catch (error) {
    console.log(error);
  }
};

export const getMaps = async () => {
  try {
    const maps = await apiUtils.apiGet(`${process.env.REACT_APP_API_URL}/maps`);
    return maps;
  } catch (error) {
    console.log(error);
  }
};

export const editMap = async (data: { id: Exclude<Map['_id'], undefined>; name: Map['name'] }) => {
  try {
    const map = await apiUtils.apiPut(`${process.env.REACT_APP_API_URL}/maps`, data);
    return map;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const saveMapZones = async (mapId: Map['_id'], zoneIds: Array<Map['zones'][number]['_id']>) => {
  try {
    const map = await apiUtils.apiPut(`${process.env.REACT_APP_API_URL}/maps/zones`, { mapId, zoneIds });
    return map;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deleteMap = async (id: Exclude<Map['_id'], undefined>) => {
  try {
    const resp = await apiUtils.apiDelete(`${process.env.REACT_APP_API_URL}/maps/${id}`);
    return resp;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
