import { ReactComponent as LeftArrow } from 'assets/icons/arrow-left-icon.svg';
import { ReactComponent as LbmIcon } from 'assets/icons/lbm-logo.svg';
import { Card, Positions, Zone } from 'constants/index';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import {
  handleCardDragStopInGame,
  resetState,
  selectCards,
  selectLot,
  selectSolutionAndFactualLotsIds,
  selectZones,
  setCards,
  setLot,
  setLotCardsAsNotPLacedInZones,
  setSolutionAndFactualLotsIds,
  setZones
} from 'redux/slices/game';
import {
  setNotificationGameZone,
  setNotificationGameZoneId,
  setNotificationMessage,
  setNotificationType
} from 'redux/slices/notification';
import { setType } from 'redux/slices/popup';
import { getLotById } from 'services/lot/lotService';
import { getZones } from 'services/zones/zonesService';
import { Button } from 'views/buttons/base';
import { Map } from 'views/components/base/map/map/map';
import { useZoneRefs } from '../../components/base/map/map/editZonesHooks';
import '../../components/base/map/map/gamemap.scss';
import { GameMapControls } from './controls/gameMapControls';

export function GameMap() {
  const dispatch = useDispatch();
  const [lotId, setLotId] = useState<string>();
  const [nbErrors, setNbErrors] = useState(0);
  const [nbAttempts, setNbAttempts] = useState(0);

  const getGameData = async (id: string) => {
    dispatch(resetState());
    const lot = await getLotById(id);
    const zoneData = await getZones();
    const zones = zoneData.zones;
    dispatch(setLot(lot));
    dispatch(setZones(zones));

    // edit the zones to mark all cards corresponding to the lot we're currently playing as not placed in zones
    const cardsPlayables = lot.cards.filter((card: Card) => !!card.zoneId);
    dispatch(setLotCardsAsNotPLacedInZones());
    dispatch(setCards(cardsPlayables));
    dispatch(setSolutionAndFactualLotsIds(zoneData.solutionAndFactualLots));
  };

  useEffect(() => {
    // get lot id from url hash
    setLotId(window.location.hash.split('#')[1]);

    if (lotId) {
      getGameData(lotId);
    }
  }, [lotId]);

  const zones = useAppSelector(selectZones);
  const cards = useAppSelector(selectCards);
  const lot = useAppSelector(selectLot);
  const solutionAndFactualLotsIds = useAppSelector(selectSolutionAndFactualLotsIds);

  // define if current lot is facutal or solution lot
  let isCurrentLotFactualOrSolution;
  if (lot.theme === 'theme__pink') {
    isCurrentLotFactualOrSolution = '-factual';
  } else if (lot.theme === 'theme__blue') {
    isCurrentLotFactualOrSolution = '-solution';
  } else if (lot.theme === 'theme__yellow') {
    isCurrentLotFactualOrSolution = '-thematique';
  } else {
    isCurrentLotFactualOrSolution = '';
  }

  const { zoneRefs } = useZoneRefs(zones);

  const handleCardDragStop = (zoneId: string, card: Card): boolean => {
    const zone = zones.find((z: Zone) => z._id === zoneId);

    if (zone && lot._id) {
      setNbAttempts(nbAttempts + 1);
      dispatch(setNotificationGameZoneId(zoneId));
      const rightZone = lot.cards.map((c: Card) => c._id).includes(card._id) && card.zoneId === zoneId;
      if (rightZone) {
        dispatch(handleCardDragStopInGame({ card, zone }));
        dispatch(setNotificationGameZone('success'));
      } else {
        setNbErrors(nbErrors + 1);
        dispatch(setNotificationGameZone('error'));
      }
      return rightZone;
    } else {
      dispatch(setNotificationType('error'));
      dispatch(setNotificationMessage('cardNotPlacedInLot'));
      return false;
    }
  };

  const backHome = () => {
    dispatch(setType('validateBackToHome'));
  };

  return (
    <>
      <NavLink to="/">
        <LbmIcon className="lbm-logo" />
      </NavLink>
      <Button
        label="backHome"
        type="button"
        classType={'secondary-button-add-card home-button'}
        translation="game"
        onClick={backHome}
        Icon={{
          Svg: LeftArrow,
          position: Positions.START
        }}
      />
      <Map
        isGame
        isAdminEdit={false}
        isProgression={false}
        mapControls={
          <GameMapControls
            isCurrentLotFactualOrSolution={isCurrentLotFactualOrSolution}
            cards={cards}
            onCardDragStop={handleCardDragStop}
            nbErrors={nbErrors}
            nbAttempts={nbAttempts}
          />
        }
        lotId={lot._id}
        zoneRefs={zoneRefs}
        solutionAndFactualLotsIds={solutionAndFactualLotsIds}
      />
    </>
  );
}
