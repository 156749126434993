import './createLotMap.scss';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setCreationStepToCards, setCreationStepToLot } from 'redux/slices/lotCreation';
import { ReactComponent as LeftArrow } from 'assets/icons/arrow-left-icon.svg';
import { ReactComponent as RightArrow } from 'assets/icons/arrow-right-icon.svg';
import { LotType, Map, Positions } from 'constants/index';
import { Button } from 'views/buttons/base';
import { selectMaps, setMaps } from '../../../../redux/slices/adminMap';
import { getMapsWithImagesAPI } from '../../../../utils/maps/mapsWithImages';
import { Map as MapComponent } from '../../../components/base/map/map/map';
import { selectLot, setCreatedLot } from '../../../../redux/slices/lotCreation';
import { MapsRowList } from '../../../maps/mapsRowList/mapsRowList';

export function CreateLotMap() {
  const dispatch = useAppDispatch();
  const createLot = useAppSelector(selectLot);

  const maps = useAppSelector(selectMaps);
  const [selectedMapId, setSelectedMapId] = useState<Map['_id']>();

  const map = maps.find((map) => map._id === selectedMapId);

  useEffect(() => {
    if (createLot.type === LotType.INTRODUCTION) {
      setCreationStepToCards();
    } else {
      if (maps.length > 0) {
        setSelectedMapId(createLot.mapId);
      } else {
        getMapsWithImagesAPI().then((mapsWithImages) => {
          dispatch(setMaps(mapsWithImages));
          setSelectedMapId(createLot.mapId);
        });
      }
    }
  }, []);

  async function setMapIdAndGoNext() {
    if (selectedMapId) {
      dispatch(setCreatedLot({ ...createLot, mapId: selectedMapId }));
    }
    dispatch(setCreationStepToCards());
  }

  return (
    <>
      <p className="edit-card-form__lot-title">{createLot.title}</p>

      <div className="edit-lot-map">
        <MapsRowList maps={maps} selectedMap={map} setSelectedMap={(m) => setSelectedMapId(m?._id)} />

        <p className="edit-lot-map__name">{map?.name}</p>

        <MapComponent isPreview isAdminEdit={false} map={map} zoneRefs={{}} />

        <div className="edit-lot-map__buttons">
          <Button
            type="button"
            label="previous"
            translation="createLot"
            classType="secondary-button-lg"
            onClick={() => dispatch(setCreationStepToLot())}
            Icon={{
              Svg: LeftArrow,
              position: Positions.START
            }}
          />
          <Button
            label="next"
            type="submit"
            translation="createLot"
            disabled={!selectedMapId}
            onClick={setMapIdAndGoNext}
            classType="primary-button-lg"
            Icon={{
              Svg: RightArrow,
              position: Positions.END
            }}
          />
        </div>
      </div>
    </>
  );
}
