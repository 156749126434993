import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { Card, Lot, GameState, Zone, SerializablePosition, TutorialType } from 'constants/index';

const getDefaultLot = () => ({
  _id: '',
  title: '',
  timeToComplete: 0,
  imageUrl: '',
  badgeUrl: '',
  theme: '',
  type: '',
  cards: [],
  isBlocked: false,
  order: 0,
  status: '',
  totalBadges: 0,
  videos: []
});

const initialState: GameState = {
  lot: getDefaultLot(),
  cards: [],
  zones: [],
  solutionAndFactualLotsIds: {
    intro: '',
    solution: '',
    factual: ''
  },
  cardPlacedIds: [],
  currentCardIdShown: '',
  cardsIdsLeftToPlace: [],
  step: 'map',
  tutoZonePosition: { x: 0, y: 0, width: 0, height: 0 }, // Initialize to a default position
  isDrag: false,
  isBlinking: false,
  isCardsPopupOpen: false,
  tutorialType: TutorialType.NONE
};

export const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    setLot: (state, action: PayloadAction<Lot>) => {
      state.lot = action.payload;
    },
    setCards: (state, action: PayloadAction<Card[]>) => {
      state.cards = action.payload;
      state.cardsIdsLeftToPlace = action.payload.map((card) => (card._id ? card._id : ''));
      state.currentCardIdShown = action.payload[0]?._id ? action.payload[0]._id : '';
    },
    setZones: (state, action: PayloadAction<any[]>) => {
      state.zones = action.payload;
    },
    setSolutionAndFactualLotsIds: (
      state,
      action: PayloadAction<{ intro: string; solution: string; factual: string }>
    ) => {
      state.solutionAndFactualLotsIds = action.payload;
    },
    setIntroLotId: (state, action: PayloadAction<string>) => {
      state.solutionAndFactualLotsIds.intro = action.payload;
    },
    setLotCardsAsNotPLacedInZones: (state) => {
      state.cards.map((card) => ({ ...card, isPlacedInZone: false }));
      state.cardPlacedIds = [];
    },
    handleCardDragStopInGame: (state, action: PayloadAction<{ card: Card; zone: Zone }>) => {
      const { card, zone } = action.payload;
      const stateZone = state.zones.find((z) => z._id === zone._id);

      if (stateZone) {
        const stateCard = state.cards.find((c) => c._id === card._id);

        if (stateCard) {
          stateCard.isPlacedInZone = true;

          if (!state.cardPlacedIds.includes(stateCard._id)) {
            state.cardPlacedIds.push(stateCard._id);
          }

          state.cardsIdsLeftToPlace = state.cardsIdsLeftToPlace.filter((cardId) => cardId !== stateCard._id);
          state.currentCardIdShown = state.cardsIdsLeftToPlace[0];
        }
      }
    },
    setTutoCardPosition: (state, action: PayloadAction<SerializablePosition>) => {
      state.tutoZonePosition = action.payload;
    },
    setCurrentCardIdShown: (state, action: PayloadAction<string>) => {
      state.currentCardIdShown = action.payload;
    },
    setTutorialType: (state, action: PayloadAction<TutorialType>) => {
      state.tutorialType = action.payload;
    },
    setIsDrag: (state, action: PayloadAction<boolean>) => {
      state.isDrag = action.payload;
    },
    moveToNextOrPreviousCard: (state, action: PayloadAction<string>) => {
      const currentCardIndex = state.cardsIdsLeftToPlace.findIndex((cardId) => cardId === state.currentCardIdShown);
      if (currentCardIndex !== -1) {
        const move = action.payload === 'next' ? 1 : -1;
        // check if we are not at the end of the array or at the beginning
        if (currentCardIndex + move >= 0 && currentCardIndex + move < state.cardsIdsLeftToPlace.length) {
          state.currentCardIdShown = state.cardsIdsLeftToPlace[currentCardIndex + move];
        }
      }
    },
    setBlinking: (state, action: PayloadAction<boolean>) => {
      state.isBlinking = action.payload;
    },
    setCardsPopup: (state, action: PayloadAction<boolean>) => {
      state.isCardsPopupOpen = action.payload;
    },
    setStepToBadge: (state) => {
      state.step = 'badge';
    },
    setStepToMap: (state) => {
      state.step = 'map';
    },
    setStepToTutorial: (state) => {
      state.step = 'tutorial';
    },
    resetState: (state) => {
      state.lot = getDefaultLot();
      state.cards = [];
      state.zones = [];
      state.solutionAndFactualLotsIds = {
        intro: '',
        solution: '',
        factual: ''
      };
      state.cardPlacedIds = [];
      state.currentCardIdShown = '';
      state.cardsIdsLeftToPlace = [];
      state.step = 'map';
      (state.tutorialType = TutorialType.NONE), (state.isDrag = false);
    }
  }
});

export const selectLot = (state: RootState) => state.game.lot;
export const selectCards = (state: RootState) => state.game.cards;
export const selectZones = (state: RootState) => state.game.zones;
export const selectSolutionAndFactualLotsIds = (state: RootState) => state.game.solutionAndFactualLotsIds;
export const selectCardPlacedIds = (state: RootState) => state.game.cardPlacedIds;
export const selectCurrentCardIdShown = (state: RootState) => state.game.currentCardIdShown;
export const selectCurrentCard = (state: RootState) =>
  state.game.cards.find((c) => c._id === state.game.currentCardIdShown)!;
export const selectCardsIdsLeftToPlace = (state: RootState) => state.game.cardsIdsLeftToPlace;
export const selectCurrentStep = (state: RootState) => state.game.step;
export const selectIsBlinking = (state: RootState) => state.game.isBlinking;
export const selectIsCardsPopupOpen = (state: RootState) => state.game.isCardsPopupOpen;
export const selectTutoZonePosition = (state: RootState) => state.game.tutoZonePosition;
export const selectTutorialType = (state: RootState) => state.game.tutorialType;
export const selectIsDrag = (state: RootState) => state.game.isDrag;
export const selectNbRemainingCardsInZone = (zoneId: string) => (state: RootState) => {
  if (state.game.zones && state.game.lot._id) {
    const zone = state.game.zones.find((z: Zone) => z._id === zoneId);
    if (!zone) return null;
    return state.game.cards.filter((card) => !card.isPlacedInZone && card.zoneId === zoneId).length;
  }
  return null;
};

export const {
  setLot,
  setCards,
  setZones,
  setSolutionAndFactualLotsIds,
  setIntroLotId,
  setIsDrag,
  setLotCardsAsNotPLacedInZones,
  handleCardDragStopInGame,
  setCurrentCardIdShown,
  moveToNextOrPreviousCard,
  setBlinking,
  setCardsPopup,
  setStepToBadge,
  setStepToMap,
  setStepToTutorial,
  setTutoCardPosition,
  setTutorialType,
  resetState
} = gameSlice.actions;
export default gameSlice.reducer;
