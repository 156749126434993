// Check file size
export const isSizeValid = (file: File): boolean => {
  const MAX_MB = 200,
    BYTES = 1024,
    TO_MB_POW = 2;

  const LIMIT_FILE_SIZE = MAX_MB * Math.pow(BYTES, TO_MB_POW);

  return file.size <= LIMIT_FILE_SIZE ? true : false;
};

// Check file format
export const isFormatValid = (file: File) => {
  const re = /(jpg|jpeg|png|svg)$/g;
  return re.test(file.name);
};
