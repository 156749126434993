import './editCardZonesControls.scss';
import { Button } from 'views/buttons/base/';
import { Card as CardComponent } from 'views/components/base/cards/Card';
import { Card, CardType, Positions } from 'constants/index';
import { ReactComponent as LeftArrow } from 'assets/icons/arrow-left-icon.svg';
import { ReactComponent as RightArrow } from 'assets/icons/arrow-right-icon.svg';
import { useAppSelector } from 'redux/hooks';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  selectCurrentDisplayedCardIndexEdit,
  selectEditLot,
  setCurrentDisplayedCardIndexEdit
} from 'redux/slices/lotEdition';
import { addCardIndexInZones, emptyCardsIndexes, emptyZones, selectCardsIndexes } from 'redux/slices/zones';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { EditMapControlsProps } from 'constants/types';
import carousel from 'utils/carousel/carouselNavigation';
import { useCardDragEvents } from 'hooks/useDragEvents';
import { selectIsDrag, setIsDrag } from 'redux/slices/game';

export function EditCardZonesControls({ cards, onCardDragStop, saveZones }: EditMapControlsProps) {
  const dispatch = useDispatch();

  const currentDisplayedCardIndex: number = useAppSelector(selectCurrentDisplayedCardIndexEdit);
  const cardsIndexesInZone: { [key: string]: number } = useAppSelector(selectCardsIndexes);
  const currentLot = useAppSelector(selectEditLot);

  const [cardsIndexes, setCardsIndexes] = useState<number[]>([]);
  const [filteredCardsIndexes, setFilteredCardsIndexes] = useState<number[]>([]);
  const [cardsIndexesInZoneValues, setCardsIndexesInZoneValues] = useState<number[]>([]);
  const [isInitialPositionSet, setIsInitialPositionSet] = useState<boolean>(false);
  const [isRenderingFinished, setIsRenderingFinished] = useState<boolean>(false);
  const isDrag = useAppSelector(selectIsDrag);

  // get next index which is not in map
  const nextCursorIndex = () => {
    carousel.nextCursorIndex(
      dispatch,
      filteredCardsIndexes,
      setCurrentDisplayedCardIndexEdit,
      currentDisplayedCardIndex,
      cardsIndexesInZone
    );
  };

  // get previous index which is not in map
  const previousCursorIndex = () => {
    carousel.previousCursorIndex(
      dispatch,
      filteredCardsIndexes,
      setCurrentDisplayedCardIndexEdit,
      currentDisplayedCardIndex,
      cardsIndexesInZone
    );
  };

  const cardDropped = (card: Card, index: number) => {
    nextCursorIndex();
    dispatch(addCardIndexInZones([card._id, index]));
  };

  // Drags events from hook
  const { onStop, hookShrinkCard, onDrag, hookDraggableCardPosition } = useCardDragEvents(onCardDragStop, cardDropped);

  const moveToCursor = (e: any, el: DraggableData) => {
    let box = el.node.getBoundingClientRect();
    let mouse_top = e.clientY;
    let mouse_left = e.clientX;
    let diff_x = mouse_left - box.left;
    let diff_y = mouse_top - box.top;
    el.node.style.top = Number(el.node.style.top.replace('px', '')) - 1 + diff_y + 'px';
    el.node.style.left = Number(el.node.style.left.replace('px', '')) - 1 + diff_x + 'px';
  };

  const [shrinkCard, setShrinkCard] = useState<boolean>(false);
  const [draggableCardPosition, setDraggableCardPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });

  useEffect(() => {
    // retrieve the indexes of the "cards" array
    // it will be used for comparison
    if (cards) {
      const indexes = cards.reduce((a: number[], e: Card, i: number) => {
        if (a && e) {
          a.push(i);
        }
        return a;
      }, []);

      const filteredIndexes = indexes.filter((index) => !Object.values(cardsIndexesInZone).includes(index));

      // no cards displayed at first initialization
      dispatch(setCurrentDisplayedCardIndexEdit(-1));

      // populate all thes indexes
      setCardsIndexes(indexes);

      // populate index which are not in zones
      setFilteredCardsIndexes(filteredIndexes);
    }
  }, []);

  useEffect(() => {
    // we filter again to refresh the cards which are not in zone
    const filteredIndexes = cardsIndexes.filter((index) => !Object.values(cardsIndexesInZone).includes(index));

    // EXPLANATION : Since useState are async under the hood
    // we need to handle render until we filter cards in zone
    // and cards in carousel.
    //
    // THREE CASES (in order) :
    //
    // FIRST: If all cards are in carousel, set current cursor to first
    // card in the carousel | first render
    //
    // SECOND: If cards are all placed in zones, if so we doesn't show
    // cards from carousel | second render
    //
    // THREE: If cards are not all placed in zones and carousel
    // so show the first card of carousel | third render
    if (filteredIndexes.length > 0 && !isInitialPositionSet) {
      dispatch(setCurrentDisplayedCardIndexEdit(filteredIndexes.at(0) as number));
      setIsInitialPositionSet(true);
    } else if (
      filteredIndexes.length === 0 &&
      Object.values(cardsIndexesInZone).length === cards.length &&
      isInitialPositionSet
    ) {
      dispatch(setCurrentDisplayedCardIndexEdit(-1));
    } else if (
      filteredIndexes.length > 0 &&
      Object.values(cardsIndexesInZone).length < cards.length &&
      Object.values(cardsIndexesInZone).length > 0 &&
      isInitialPositionSet &&
      !isRenderingFinished
    ) {
      dispatch(setCurrentDisplayedCardIndexEdit(filteredIndexes.at(0) as number));
      setIsRenderingFinished(true);
    }

    setFilteredCardsIndexes(filteredIndexes);

    // populate index array which are in zones
    setCardsIndexesInZoneValues(Object.values(cardsIndexesInZone));
  }, [cardsIndexesInZone, cardsIndexes]);

  // Link local useState with hook useState
  useEffect(() => {
    setShrinkCard(hookShrinkCard);
    setDraggableCardPosition(hookDraggableCardPosition);
  }, [hookShrinkCard, hookDraggableCardPosition]);

  // Empty zones and card indexes when component is unmount
  useEffect(() => {
    return () => {
      dispatch(emptyCardsIndexes());
      dispatch(emptyZones());
      dispatch(setCurrentDisplayedCardIndexEdit(0));
    };
  }, []);

  let isCurrentLotFactualOrSolution: string;
  if (cards[0].lotType === 'factuel') {
    isCurrentLotFactualOrSolution = '-factual';
  } else if (cards[0].lotType === 'solution') {
    isCurrentLotFactualOrSolution = '-solution';
  } else if (cards[0].lotType === 'thematique') {
    isCurrentLotFactualOrSolution = '-thematique';
  } else {
    isCurrentLotFactualOrSolution = '';
  }

  return (
    <>
      <p className="edit-card-zones-controls__title">{currentLot.title}</p>
      <div className="edit-card-zones-controls__container">
        <p>
          {cardsIndexesInZoneValues.length === cards.length
            ? 'Veuillez retirer une carte de la map pour modifier sa zone d’emplacement '
            : 'Veuillez placer la carte dans une zone '}
          ({cardsIndexesInZoneValues.length < 1 ? 0 : cardsIndexesInZoneValues.length}/{cards.length})
        </p>
        {cards.map((card, index) => (
          <div
            key={index}
            className={
              (isDrag && currentDisplayedCardIndex === index) || (currentDisplayedCardIndex === -1 && index === 0)
                ? `edit-card-zones-controls__no-card-border${isCurrentLotFactualOrSolution}`
                : ''
            }
          >
            <div
              style={currentDisplayedCardIndex !== -1 && index === currentDisplayedCardIndex ? {} : { display: 'none' }}
            >
              <Draggable
                onStart={(e, el) => {
                  moveToCursor(e, el);
                  dispatch(setIsDrag(true));
                }}
                position={draggableCardPosition}
                defaultClassName="relative"
                onStop={(e: DraggableEvent, el: DraggableData) => {
                  el.node.style.top = '0px';
                  el.node.style.left = '0px';
                  onStop(e, card, index);
                }}
                onDrag={(e: DraggableEvent, positionData) => onDrag(e, card, positionData)}
              >
                <div>
                  <CardComponent cardType={CardType.map} card={card} shrinkCard={shrinkCard} />
                </div>
              </Draggable>
            </div>
          </div>
        ))}
        <div className="edit-card-zones-controls__buttons">
          {cardsIndexesInZoneValues.length < cards.length && (
            <>
              {currentDisplayedCardIndex !== filteredCardsIndexes[0] && (
                <Button
                  label="Précédent"
                  type="button"
                  classType="secondary-button-lg"
                  translation="createCard"
                  onClick={previousCursorIndex}
                  Icon={{
                    Svg: LeftArrow,
                    position: Positions.START
                  }}
                />
              )}
              <Button
                label="Suivant"
                type="button"
                classType={
                  filteredCardsIndexes.length < 2 || currentDisplayedCardIndex === filteredCardsIndexes.at(-1)
                    ? 'off-button-lg'
                    : 'primary-button-lg'
                }
                translation="createCard"
                onClick={nextCursorIndex}
                Icon={{
                  Svg: RightArrow,
                  position: Positions.END
                }}
              />
            </>
          )}
          {cardsIndexesInZoneValues.length === cards.length && (
            <>
              <Button
                label="Sauvegarder"
                type="button"
                classType="primary-button-lg"
                translation="createCard"
                onClick={saveZones}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}
