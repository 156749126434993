import '../../components/base/map/map/gamemap.scss';
import { ReactComponent as LeftArrow } from 'assets/icons/arrow-left-icon.svg';
import { ReactComponent as LbmIcon } from 'assets/icons/lbm-logo.svg';
import { Card, Positions, SerializablePosition, TutorialType, Zone, ZonesTypeMap } from 'constants/index';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'redux/hooks';
import {
  handleCardDragStopInGame,
  selectCards,
  selectTutorialType,
  selectZones,
  setCards,
  setLot,
  setLotCardsAsNotPLacedInZones,
  setStepToMap,
  setTutoCardPosition,
  setTutorialType,
  setZones
} from 'redux/slices/game';
import { setNotificationGameZone, setNotificationGameZoneId } from 'redux/slices/notification';
import { setType } from 'redux/slices/popup';
import { setZonesTypeMap } from 'redux/slices/zones';
import { getFactualLot } from 'services/lot/lotService';
import { getZones } from 'services/zones/zonesService';
import { Button } from 'views/buttons/base';
import { useZoneRefs } from 'views/components/base/map/map/editZonesHooks';
import { Map } from 'views/components/base/map/map/map';
import { TutorialMapControls } from './controls/tutorialMapControls';
import { NavLink } from 'react-router-dom';

export function TutorialMap() {
  const dispatch = useDispatch();

  const [lotId, setLotId] = useState<string>();
  const cards = useAppSelector(selectCards);
  const tutorialType = useAppSelector(selectTutorialType);
  const zones = useAppSelector(selectZones);
  const { zoneRefs } = useZoneRefs(zones);
  const [dropZone, setDropZone] = useState<Zone | undefined>();
  const [dropZonePosition, setDropZonePosition] = useState<SerializablePosition | undefined>();

  const getTutorialData = async () => {
    const lot = await getFactualLot();
    const zoneData = await getZones();
    const zones = zoneData.zones;

    dispatch(setLot(lot));
    dispatch(setZones(zones));
    dispatch(setCards(lot.cards));
    dispatch(setLotCardsAsNotPLacedInZones());
    setLotId(lot._id);
  };

  useEffect(() => {
    dispatch(setZonesTypeMap(ZonesTypeMap.tutorial));
    getTutorialData();
  }, [lotId]);

  // On unmount we reset tutorial type to none
  useEffect(() => {
    return () => {
      dispatch(setTutorialType(TutorialType.NONE));
      dispatch(setStepToMap());
    };
  }, []);

  const backToHome = () => {
    dispatch(setType('validateBackToHome'));
  };

  const findZone = () => {
    let foundZone: Zone | undefined;
    let foundZonePosition = null;

    const card = cards[0];
    if (card && lotId) {
      // Find the zone where the card belongs to
      foundZone = zones.find((zone) => card.zoneId === zone._id);
      setDropZone(foundZone);

      // Find the position of the zone where the card belongs to
      if (foundZone && foundZone._id in zoneRefs) {
        foundZonePosition = zoneRefs[foundZone._id]?.current?.getBoundingClientRect();
        setDropZonePosition(foundZonePosition);
      }
    }

    if (foundZonePosition) {
      const serializablePosition = {
        x: foundZonePosition.x,
        y: foundZonePosition.y,
        width: foundZonePosition.width,
        height: foundZonePosition.height
      };

      if (foundZone && lotId) {
        dispatch(setTutoCardPosition(serializablePosition));
      }
    }
  };

  const handleCardDragStop = (zoneId: string, card: Card): boolean => {
    if (dropZone?._id === zoneId && lotId && dropZonePosition) {
      dispatch(handleCardDragStopInGame({ card, zone: dropZone }));
      dispatch(setNotificationGameZone('success'));
      dispatch(setNotificationGameZoneId(zoneId));
      return true;
    } else {
      dispatch(setNotificationGameZone('error'));
      dispatch(setNotificationGameZoneId(zoneId));
      return false;
    }
  };

  return (
    <>
      <NavLink to="/">
        <LbmIcon className="lbm-logo" />
      </NavLink>
      {tutorialType !== TutorialType.EXAMPLE && (
        <Button
          label="backHome"
          type="button"
          classType={'secondary-button-md home-button'}
          translation="game"
          onClick={backToHome}
          Icon={{
            Svg: LeftArrow,
            position: Positions.START
          }}
        />
      )}
      <Map
        isGame
        isTutorial
        isAdminEdit={false}
        mapControls={
          <TutorialMapControls
            card={cards[0]}
            onCardDragStop={handleCardDragStop}
            onStepOne={getTutorialData}
            onFindZone={findZone}
          />
        }
        lotId={lotId ?? ''}
        zoneRefs={zoneRefs}
      />
    </>
  );
}
