export enum LotCreationSteps {
  video,
  lot,
  cards,
  map,
  zones
}

export enum LotEditionSteps {
  lot,
  cards,
  video,
  newCard,
  map,
  zones
}

export const enum CheckedModule {
  text = 'text',
  textAndImage = 'textAndImage',
  image = 'image'
}

export const enum CardPopupType {
  creation = 'creation',
  edition = 'edition'
}

export const enum CardType {
  creation = 'creation',
  edition = 'edition',
  map = 'map'
}

export const enum LotType {
  INTRODUCTION = 'introduction',
  FACTUEL = 'factuel',
  SOLUTION = 'solution',
  THEMATIQUE = 'thematique',
  TUTORIAL = 'tutorial'
}

export const enum SectorTypePopup {
  DUPLICATE = 'duplicate',
  SELECTION = 'selection',
  UNLOCK = 'unlock',
  LOCKED = 'locked'
}

export const enum SectorStepPopup {
  ACTION = 'action',
  CONFIRM = 'confirm'
}

export const enum TutorialType {
  GAME = 'game',
  EXAMPLE = 'example',
  NONE = 'none'
}

export const enum ZonesTypeMap {
  creation = 'creation',
  edition = 'edition',
  tutorial = 'tutorial'
}

export const enum ManageStep {
  sector,
  detail
}
