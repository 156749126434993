import { UserInfo } from 'constants/index';
import jwt_decode from 'jwt-decode';

const getUserInfoFromToken = (token: string) => {
  const decodedJwt: UserInfo = jwt_decode(token);

  const tokenExpiration = decodedJwt.exp;
  const emailId = decodedJwt.emailId;
  const firstName = decodedJwt.firstName;
  const lastName = decodedJwt.lastName;
  const role = decodedJwt.role;
  const id = decodedJwt.id;
  const currentSector = decodedJwt.currentSector;

  return { email: decodedJwt.email, token, tokenExpiration, emailId, firstName, lastName, role, id, currentSector };
};

export { getUserInfoFromToken };
