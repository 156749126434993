import { Sector } from 'constants/index';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { emptySector, selectSector, selectSectors, setSector, setSectors } from 'redux/slices/manageSectors';
import { setType } from 'redux/slices/popup';
import { selectUserId, setUserSector, setUserToken } from 'redux/slices/user';
import { getAllSectors } from 'services/sectors/sectorService';
import { setUserCurrentSector } from 'services/user/userServices';
import customDropDownStyles from 'views/admin/editLot/editLotForm/customDropDownStyles';
import { Button } from 'views/buttons/base';
import { DropDownInput } from 'views/components/base/inputs/DropDownInput';
import './SectorSelection.scss';

export function SectorSelection() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['']);

  const currentSector = useAppSelector(selectSector);
  const sectors = useAppSelector(selectSectors).filter((s) => s.published);
  const userId = useAppSelector(selectUserId);

  const [validity, setValidity] = useState<boolean>(false);

  const getSectors = async () => {
    const sectors = await getAllSectors();
    dispatch(setSectors(sectors));
  };

  useEffect(() => {
    getSectors();
  }, []);

  const handleSelectChange = (sector: unknown) => {
    dispatch(setSector(sector as Sector));
    setValidity(true);
  };

  const validateSectorSelection = async () => {
    if (currentSector && currentSector._id) {
      // We update the user and the token
      const { updatedUser, updatedToken } = await setUserCurrentSector(userId, currentSector?._id);

      if (updatedUser && updatedToken) {
        // Update the existing token with the new one containing
        // currentSector value
        localStorage.setItem('token', updatedToken);
        dispatch(setUserToken({ token: updatedToken }));
        dispatch(setUserSector({ sectorId: updatedUser.currentSector }));
        dispatch(setType(''));
        dispatch(emptySector());
      }
    }
  };

  return (
    <div className="sector-selection">
      <p className="sector-selection__title">Bienvenue sur Little Big Map</p>
      <p className="sector-selection__subtitle">
        À quel secteur / métier vous vous identifiez  le plus ?
      </p>
      <DropDownInput
        key={sectors.length}
        options={sectors}
        placeholder={t('Sélectionner votre secteur / métier')}
        styles={customDropDownStyles}
        onChange={handleSelectChange}
        value={currentSector}
        defaultValue={null}
      />
      <div className="sector-selection__buttons">
        <Button
          label="Valider"
          type="submit"
          classType={validity && currentSector ? 'primary-button-lg' : 'off-button-lg'}
          translation=""
          onClick={() => validateSectorSelection()}
        />
      </div>
    </div>
  );
}
