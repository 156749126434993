import { useEffect, useState } from 'react';
import { selectLot, resetState, selectSolutionAndFactualLotsIds } from 'redux/slices/game';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getFile } from 'services/file/fileService';
import { ReactComponent as LbmIcon } from 'assets/icons/lbm-logo.svg';
import { NavLink, useNavigate } from 'react-router-dom';
import { ReactComponent as DavidsonIcon } from 'assets/icons/davidson-logo.svg';
import { ReactComponent as LeftArrow } from 'assets/icons/arrow-left-icon.svg';
import { Button } from 'views/buttons/base';
import { Positions } from 'constants/index';
import { useTranslation } from 'react-i18next';
import './badge.scss';

export function Badge() {
  const { t } = useTranslation(['badge']);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const solutionAndFactualLotsIds = useAppSelector(selectSolutionAndFactualLotsIds);
  const lot = useAppSelector(selectLot);
  const [badgeSrc, setBadgeSrc] = useState<string | undefined>();
  const [titleClass, setTitleClass] = useState<string | undefined>();
  const isDav = process.env.REACT_APP_IS_DAV === 'true';

  useEffect(() => {
    setTitleClass(`badge__title badge__title__${lot.theme}`);

    const getImage = async () => {
      const blob = await getFile(lot.badgeUrl);
      if (blob) {
        const url = URL.createObjectURL(blob);
        setBadgeSrc(url);
      }
    };

    getImage();
    return () => {
      if (badgeSrc) {
        URL.revokeObjectURL(badgeSrc);
      }
      dispatch(resetState());
    };
  }, []);

  const backHome = () => {
    navigate('/');
  };

  const redoGame = () => {
    if (solutionAndFactualLotsIds && solutionAndFactualLotsIds.intro === lot._id) {
      dispatch(resetState());
      navigate('/introduction');
    } else {
      const currentLotId = lot._id;
      dispatch(resetState());
      navigate(`/game#${currentLotId}`);
    }
  };

  return (
    <>
      <div className="badge">
        <NavLink to="/">
          <LbmIcon className="badge__logo" />
        </NavLink>
        {isDav && <DavidsonIcon className="badge__davidson-logo" />}
        <div className="badge__container">
          <div className="badge__content-container">
            <h2 className={titleClass}>{t('congrats')}</h2>
            <p className="badge__text">{t('finished') + ' ' + lot.title} </p>
            <p className="badge__text">{t('badge')}</p>
            <img className="badge__image" src={badgeSrc} />
          </div>
        </div>
        <div className="badge__buttons-container">
          <Button label="redo" type="button" classType={'secondary-button-xs'} translation="badge" onClick={redoGame} />
          <Button label="home" type="button" classType={'primary-button-xs'} translation="badge" onClick={backHome} />
        </div>
      </div>
    </>
  );
}
