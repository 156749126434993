import Select, { ActionMeta, StylesConfig, components } from 'react-select';
import { ReactComponent as DropDownIcon } from 'assets/icons/dropdown.svg';

// NOTE: Not possible to move interface in type file because of
// specifics type in interface
interface DropDownProps {
  options: { value: string; label: string }[];
  placeholder: string;
  styles: StylesConfig;
  onChange: (newValue: unknown, actionMeta: ActionMeta<unknown>) => void;
  value?: { value: string; label: string } | null;
  defaultValue?: { value: string; label: string } | null;
}

const { DropdownIndicator } = components;

// Custom dropdown icon
const CustomDropdownIndicator = (props: any) => {
  return (
    <DropdownIndicator {...props}>
      <DropDownIcon />
    </DropdownIndicator>
  );
};

export function DropDownInput({ options, placeholder, styles, onChange, value, defaultValue }: DropDownProps) {
  return value !== null && defaultValue !== null ? (
    <Select
      components={{ DropdownIndicator: CustomDropdownIndicator }}
      value={value}
      options={options}
      placeholder={placeholder}
      styles={styles}
      onChange={onChange}
    />
  ) : (
    <Select
      components={{ DropdownIndicator: CustomDropdownIndicator }}
      defaultValue={defaultValue}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      styles={styles}
    />
  );
}
