import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { Card, LotEditionState, LotEditionSteps } from 'constants/index';
import { getDefaultLot } from './lotCreation';

const initialState: LotEditionState = {
  currentEditionStep: LotEditionSteps.lot,
  lot: {
    ...getDefaultLot(),
    _id: ''
  },
  editCard: undefined,
  filenames: {},
  currentDisplayedCardIndex: 0
};

// TODO: Update of the current user
export const lotEditionSlice = createSlice({
  name: 'lotEdition',
  initialState,
  reducers: {
    setEditLot: (state, action: PayloadAction<LotEditionState['lot']>) => {
      state.lot = action.payload;
    },
    setEditionStepToEditLot: (state) => {
      state.currentEditionStep = LotEditionSteps.lot;
    },
    setEditionStepToCards: (state) => {
      state.currentEditionStep = LotEditionSteps.cards;
    },
    setEditionStepToNewCard: (state) => {
      state.currentEditionStep = LotEditionSteps.newCard;
    },
    setEditionStepToVideo: (state) => {
      state.currentEditionStep = LotEditionSteps.video;
    },
    setEditionStepToMap: (state) => {
      state.currentEditionStep = LotEditionSteps.map;
    },
    setEditionStepToZones: (state) => {
      state.currentEditionStep = LotEditionSteps.zones;
    },
    setEditionCard: (state, action: PayloadAction<Card>) => {
      state.editCard = action.payload;
    },
    addFilename: (state, action: PayloadAction<string[]>) => {
      state.filenames = { ...state.filenames, [action.payload[0]]: action.payload[1] };
    },
    removeFilename: (state, action: PayloadAction<string>) => {
      const next = { ...state.filenames };
      delete next[action.payload];
      state.filenames = next;
    },
    editRemoveCards: (state, action: PayloadAction<string>) => {
      const cardId = action.payload;
      const i = state.lot.cards.map((card) => card._id).indexOf(cardId);

      state.lot.cards.splice(i, 1);
    },
    updateEditedCardInLot: (state, action: PayloadAction<Card>) => {
      const cardData = action.payload;

      state.lot.cards = state.lot.cards.map((card: Card) =>
        card._id === cardData._id
          ? { ...card, content: cardData.content, moduleType: cardData.moduleType, imageUrl: cardData.imageUrl }
          : card
      );
    },
    resetLotEditionState: (state) => {
      state.currentEditionStep = LotEditionSteps.lot;
      state.lot = {
        ...getDefaultLot(),
        _id: ''
      };
      state.editCard = undefined;
      state.filenames = {};
    },
    setContinueEditId: (state, action: PayloadAction<string>) => {
      state.lot._id = action.payload;
    },
    setCurrentDisplayedCardIndexEdit: (state, action: PayloadAction<number>) => {
      state.currentDisplayedCardIndex = action.payload;
    },
    emptyEditCards: (state) => {
      state.lot.cards = [];
    }
  }
});

export const selectCurrentDisplayedCardIndexEdit = (state: RootState) => state.lotEdition.currentDisplayedCardIndex;
export const selectCurrentStep = (state: RootState) => state.lotEdition.currentEditionStep;
export const selectEditCard = (state: RootState) => state.lotEdition.editCard;
export const selectEditLot = (state: RootState) => state.lotEdition.lot;
export const selectFilenames = (state: RootState) => state.lotEdition.filenames;

export const {
  addFilename,
  editRemoveCards,
  emptyEditCards,
  setCurrentDisplayedCardIndexEdit,
  setEditLot,
  setEditionCard,
  setEditionStepToCards,
  setEditionStepToMap,
  setEditionStepToNewCard,
  setEditionStepToZones,
  removeFilename,
  updateEditedCardInLot,
  resetLotEditionState,
  setContinueEditId,
  setEditionStepToEditLot,
  setEditionStepToVideo
} = lotEditionSlice.actions;
export default lotEditionSlice.reducer;
