import './SectorDuplicate.scss';
import { Button } from 'views/buttons/base';
import { CommonTextInput } from 'views/components/base/inputs/TextInput';
import { selectSector, setSector, setSectors } from 'redux/slices/manageSectors';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setNotificationMessage, setNotificationType } from 'redux/slices/notification';
import { checkSectorLabel, createSector, getAllSectors, publishSector } from 'services/sectors/sectorService';
import { setType } from 'redux/slices/popup';
import { Sector } from 'constants/index';

export function SectorDuplicate() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['']);

  const currentSector = useAppSelector(selectSector);

  const [label, setLabel] = useState<string>('');
  const [textWasChanged, setTextWasChanged] = useState<boolean>(false);
  const [validity, setValidity] = useState<boolean>(false);

  const getSectors = async (): Promise<void> => {
    const sectors = await getAllSectors();
    dispatch(setSectors(sectors));
  };

  useEffect(() => {
    return () => {
      getSectors();
    };
  }, []);

  const onChange = (label: string): void => {
    setLabel(label);
  };

  const validateSectorPublish = async () => {
    const sectorLabelExist = await checkSectorLabel(label);
    if (!sectorLabelExist.exists && currentSector && currentSector._id) {
      const sectorInfo: Sector = {
        label: label,
        etapes: currentSector.etapes,
        archive: currentSector.archive,
        value: label,
        published: currentSector.published
      };

      const newSector = await createSector(sectorInfo);

      if (newSector) {
        dispatch(setType('duplicateSectorConfirm'));
        dispatch(setSector(newSector));
      }
    }
  };

  return (
    <div className="sector-duplication">
      <p className="sector-duplication__title">Veuillez renseigner le titre du secteur / métier dupliqué</p>
      <CommonTextInput
        isEdit={false}
        onChange={onChange}
        inputPlaceholder={t('Créer un secteur / métier (30 caractères max.)')}
        labelPlaceholder={t('Titre du secteur / métier')}
        labelClasses="lot-form__input-label"
        errorMessage={t('Le titre du secteur / métier doit faire entre 1 et 30 caracteres')}
        validationRules={{ isRequired: true, maxLength: 30, minLength: 0 }}
        setValidity={setValidity}
        textWasChanged={setTextWasChanged}
        existingValue={label}
        type="sector"
      />
      <div className="sector-duplication__buttons">
        <Button
          label="Annuler"
          type="submit"
          classType={'secondary-button-lg'}
          translation=""
          onClick={() => dispatch(setType(''))}
        />
        <Button
          label="Dupliquer"
          type="submit"
          classType={textWasChanged && validity ? 'primary-button-lg' : 'off-button-lg'}
          translation=""
          onClick={() => validateSectorPublish()}
        />
      </div>
    </div>
  );
}
