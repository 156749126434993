import { useEffect, useState } from 'react';
import { getCSV, getStats } from 'services/statistics/statisticsService';
import { Statistics as StatisticsType } from 'constants/index';
import './statistics.scss';
import { Button } from 'views/buttons/base';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectEmail } from 'redux/slices/user';
import { resetUserProgression } from 'services/user/userServices';
import { setNotificationType, setNotificationMessage } from 'redux/slices/notification';

export function Statistics() {
  const [stats, setStats] = useState<StatisticsType>();
  const [loading, setLoading] = useState<boolean>(false);
  const userMail = useAppSelector(selectEmail);
  const dispatch = useAppDispatch();

  const userCanResetProgression = [
    'celine.diep@davidson.group',
    'enzo.bianchi@davidson.group',
    'sofiane.boucard@davidson.group'
  ];

  useEffect(() => {
    getStats().then(setStats);
  }, []);

  const resetProgression = async () => {
    const response = await resetUserProgression(userMail);
    if (response.lotsFinished) {
      dispatch(setNotificationType('validation'));
      dispatch(setNotificationMessage('La progression a été reset !'));
    } else {
      dispatch(setNotificationType('error'));
      dispatch(setNotificationMessage('Une erreur est survenue lors du reset de la progression'));
    }
  };

  const exportCSV = async () => {
    setLoading(true);
    const response = await getCSV();
    const url = window.URL.createObjectURL(
      new Blob([response], {
        type: 'text/csv'
      })
    );
    setLoading(false);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `LBMStats_${new Intl.DateTimeFormat('fr').format(new Date()).replace('/', '-')}.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const sortedThematiques = stats?.thematiqueCompleted
    ? Object.entries(stats.thematiqueCompleted).sort(([, aCount], [, bCount]) => bCount - aCount)
    : [];

  return (
    <>
      <div className="stats__container">
        {userMail && userCanResetProgression.includes(userMail) && (
          <>
            <p className="stats__number">
              <strong>Reset ma progression :</strong>
            </p>
            <div className="stats__csv">
              <Button
                label="Reset ma progression"
                type="button"
                classType={'primary-button-lg'}
                translation="createCard"
                onClick={resetProgression}
              />
            </div>
          </>
        )}
        <p className="stats__number">
          <strong>Exporter les statistiques :</strong>{' '}
        </p>
        <div className="stats__csv">
          {!loading && (
            <Button
              label="Fichier CSV"
              type="button"
              classType={'primary-button-lg'}
              translation="createCard"
              onClick={exportCSV}
            />
          )}
          {loading && <div className="stats__loader"></div>}
        </div>
        <p className="stats__number">
          <strong>Nombre total d'utilisateurs:</strong>{' '}
          <span className="stats__pink-number">{stats?.usersLoggedIn}</span>
        </p>
        <table>
          <thead>
            <tr>
              <th>Titre du Lot</th>
              <th>Nombre de badges obtenus</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Introduction</td>
              <td>{stats?.introductionCompleted}</td>
            </tr>
            <tr>
              <td>Factuel</td>
              <td>{stats?.factualCompleted}</td>
            </tr>
            <tr>
              <td>Pistes de solutions</td>
              <td>{stats?.solutionCompleted}</td>
            </tr>
            {sortedThematiques.map(([lotTitle, count], index) => (
              <tr key={index}>
                <td>{lotTitle}</td>
                <td>{count}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
