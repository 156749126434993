import { Card, CheckedModule } from 'constants/index';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectEditLot, setEditionCard } from 'redux/slices/lotEdition';
import { selectPopupData, setEditCardModuleType, setPopupData, setType } from 'redux/slices/popup';
import { getFile } from 'services/file/fileService';
import fileEncoding from 'utils/file/fileEncoding';
import { Button } from 'views/buttons/base';
import '../Card.scss';
import './CardEdition.scss';

export function CardEdition({ card }: { card: Card }) {
  const dispatch = useAppDispatch();
  const data = useAppSelector(selectPopupData);
  const lotId = useAppSelector(selectEditLot)?._id;
  const [isHovering, setIsHovering] = useState(false);
  const [imageSrc, setImageSrc] = useState<string | undefined>();

  useEffect(() => {
    // TODO: create an array of modified images (with file format);
    // to update and then push file on server before the editCardsInDb event.
    // Each response filepath need to be set as card image.
    // Create a dictionary of cards in editCardForm
    const getImage = async () => {
      const blob = await getFile(card.imageUrl as string);

      if (blob) {
        const url = URL.createObjectURL(blob);
        setImageSrc(url);

        dispatch(setPopupData(url));
      }
    };

    const convertDataURL = async () => {
      const file = await fileEncoding.toFile(card.imageUrl as string, data);

      if (file) {
        const url = URL.createObjectURL(file);
        setImageSrc(url);

        dispatch(setPopupData(url));
      }
    };

    if (card.imageUrl) {
      if (fileEncoding.isBase64Encoded(card.imageUrl) && typeof data === 'string') {
        convertDataURL();
      } else {
        getImage();
      }
    }

    return () => {
      if (imageSrc) {
        URL.revokeObjectURL(imageSrc);
      }
    };
  }, [card]);

  const openModulePopUp = (module: CheckedModule) => {
    dispatch(setType('editCard'));
    dispatch(setEditionCard(card));
    dispatch(setEditCardModuleType(module));
    if (module === CheckedModule.textAndImage || CheckedModule.image) {
      dispatch(setPopupData(imageSrc));
    }
  };

  const deleteCardInDb = async () => {
    if (card._id && lotId && card.content) {
      dispatch(setPopupData({ cardId: card._id, lotId, content: card.content }));
      dispatch(setType('deleteCard'));
    }
  };

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const CardText = (
    <div className="create-card-form__card-content-not-empty">
      <div dangerouslySetInnerHTML={{ __html: card.content ? card.content : '' }}></div>
    </div>
  );

  const CardTextImage = (
    <>
      <div
        className="create-card-form__card-module__text-not-empty"
        dangerouslySetInnerHTML={{ __html: card.content ? card.content : '' }}
      ></div>
      <div className="create-card-form__card-module__image-upload card-form__image">
        {card.imageUrl ? <img src={imageSrc} alt="preview image" /> : <></>}
      </div>
    </>
  );

  const CardImage = (
    <div className="create-card-form__card-content">
      {card.imageUrl ? <img src={imageSrc} alt="preview image" /> : <></>}
    </div>
  );

  return (
    <div
      className={'card-form__' + card.lotType + ' create-card-form__card-module'}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      {isHovering && (
        <div className="card-form__hover hover-edition">
          <Button
            label="deleteCard"
            type="button"
            classType={'secondary-button-md'}
            translation="editCard"
            onClick={deleteCardInDb}
          />
          <Button
            label="editCard"
            type="button"
            classType={'primary-button-md'}
            translation="editCard"
            onClick={() => {
              openModulePopUp(card.moduleType);
            }}
          />
          <a href="#" className="draggable-handle">
            Déplacer
          </a>
        </div>
      )}
      {!isHovering ? card.moduleType === CheckedModule.text ? CardText : <></> : null}
      {!isHovering ? card.moduleType === CheckedModule.textAndImage ? CardTextImage : <></> : null}
      {!isHovering ? card.moduleType === CheckedModule.image ? CardImage : <></> : null}
    </div>
  );
}
