import { CompletedLotsIds, Lot, Sector } from 'constants/index';

export const manageUserProgression = (
  dispatch: any,
  setType: (newState: string) => void,
  lots: Lot[],
  currentSector: Sector,
  progression: CompletedLotsIds,
  setCurrentStep: (id: string, index: number) => Promise<void>
) => {
  // filtered lots array which are published lot and included in steps
  const publishedLots = lots.filter((lot: Lot) =>
    lot.status === 'published' && currentSector.etapes !== undefined
      ? currentSector.etapes.flat().includes(lot._id as string)
      : false
  );

  // structured steps of currentSector which have been filtered to includes only
  // published lot and empty value ('') if lot not included
  const filteredSteps =
    currentSector.etapes !== undefined
      ? currentSector.etapes.map((step: string[]) =>
          step.map((lotId: string) => (publishedLots.map((lot: Lot) => lot._id).includes(lotId) ? lotId : ''))
        )
      : [];

  // clean array from empty subarray and
  // remove unpublished lot
  const steps = filteredSteps
    .filter((step: string[]) => step.some((lotId: string) => lotId !== ''))
    .map((step: string[]) => step.filter((lotId: string) => lotId != ''));

  // flattened steps
  const flatSteps = steps.flat();

  // retrieve last lot id step index from progression
  let lastUnlockedIndexStep = steps.findIndex((step: string[]) =>
    step.includes(progression.lotsFinished.at(-1) as string)
      ? step.includes(progression.lotsFinished.at(-1) as string)
      : false
  );

  // if last lot id done is inferior to the current user step, increase lastUnlockedIndexStep
  lastUnlockedIndexStep =
    lastUnlockedIndexStep < progression.currentStep ? progression.currentStep : lastUnlockedIndexStep;

  // retrieve lots in progression.thematique from currentSector.etapes[lastUnlockedIndexStep]
  const sectorStepsOfIndex = steps[lastUnlockedIndexStep];
  // check if lastUnlockedIndexStep is not undefined and if there is step
  const currentSectorExist = lastUnlockedIndexStep !== undefined && steps[lastUnlockedIndexStep + 1] !== undefined;
  // check if progression is completed, if so, we access to next step
  const currentStepCompleted =
    sectorStepsOfIndex !== undefined
      ? sectorStepsOfIndex.every((lotId: string) => progression.lotsFinished.includes(lotId))
      : false;

  let remainingLots: string[] = [];
  let lockedLots: string[] = [];

  if (currentSectorExist && currentStepCompleted) {
    // increment lastUnlockedIndexStep in order to access to next step
    lastUnlockedIndexStep = lastUnlockedIndexStep + 1;

    // check if we need to update current user step
    if (lastUnlockedIndexStep > progression.currentStep) {
      setCurrentStep(progression._id, lastUnlockedIndexStep);
      dispatch(setType('unlockNextStepConfirm'));
    } else {
      // if last unlocked index step is inferior to progression.currentStep
      // it means that the user did a lot from step n-1
      // so we need to calibrate the index step on currentStep
      lastUnlockedIndexStep = progression.currentStep;
    }
  }

  if (lastUnlockedIndexStep !== -1 && steps.length > 0 && flatSteps.length > 0) {
    // retrieve lots that are not completed but playable from next step
    remainingLots = flatSteps.filter((lotId: string) =>
      !progression.lotsFinished.includes(lotId) && steps
        ? steps.findIndex((step: string[]) => step?.includes(lotId)) <= lastUnlockedIndexStep
        : 0
    );

    // retrieve lots that are not completed and not playable from next step
    lockedLots = flatSteps.filter((lotId: string) =>
      !progression?.lotsFinished.includes(lotId) && steps
        ? steps.findIndex((step: string[]) => step?.includes(lotId)) > lastUnlockedIndexStep
        : 0
    );
  }

  // sort published lots in order to keep
  // the same order as the steps
  publishedLots.sort((a: Lot, b: Lot) => {
    const priorityA = flatSteps.indexOf(a._id as string);
    const priorityB = flatSteps.indexOf(b._id as string);

    if (priorityA === -1 && priorityB === -1) {
      return 0;
    }

    if (priorityA === -1) {
      return -1;
    }

    if (priorityB === -1) {
      return 1;
    }

    return priorityA - priorityB;
  });

  return { remainingLots, lockedLots, flatSteps, publishedLots, steps };
};
