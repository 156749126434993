import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectEditLot, setEditionStepToMap, setEditionStepToVideo, setEditLot } from 'redux/slices/lotEdition';
import { GenericLotForm } from 'views/components/base/form/genericLotForm';
import { DropDownInput } from 'views/components/base/inputs/DropDownInput';
import customDropDownStyles from './customDropDownStyles';
import {
  getAllLots,
  getLotById,
  editLot as editLotApi,
  getLotTypes,
  checkLotTitle,
  deleteLot
} from 'services/lot/lotService';
import { getFile, addFile, deleteFile } from 'services/file/fileService';
import { Lot, LotType, LotType as LotTypeEnum, LotTypeObject } from 'constants/index';
import { setNotificationType, setNotificationMessage } from 'redux/slices/notification';
import { Button } from 'views/buttons/base';

export function EditLotForm() {
  const { t } = useTranslation(['createLot']);
  const dispatch = useAppDispatch();
  const editLot = useAppSelector(selectEditLot);

  const [existingLotTypes, setExistingLotTypes] = useState<LotTypeObject>();
  const [lotInfo, setLotInfo] = useState<Lot>();
  //lotEdition state
  const [selectedLot, setSelectedLot] = useState<{ label: string; value: string } | null>(null);
  const [editLotTitle, setEditLotTitle] = useState<string | undefined>();
  const [editLotTitleWasChanged, setEditLotTitleWasChanged] = useState<boolean>(false);
  const [editLotTimeToComplete, setEditLotTimeToComplete] = useState<string | undefined>();
  const [editLotTimeToCompleteWasChanged, setEditLotTimeToCompleteWasChanged] = useState<boolean>(false);
  const [editLotImage, setEditLotImage] = useState<File | undefined>();
  const [lotImageWasChanged, setLotImageWasChanged] = useState<boolean>(false);
  const [editLotBadge, setEditLotBadge] = useState<File | undefined>();
  const [lotBadgeWasChanged, setLotBadgeWasChanged] = useState<boolean>(false);
  const [lotType, setLotType] = useState<LotTypeEnum>();
  const [lotTypeWasChanged, setLotTypeWasChanged] = useState<boolean>(false);

  //form validation state
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);
  const [lotTitleValid, setLotTitleValid] = useState<boolean>(true);
  const [lotTimeToCompleteValid, setLotTimeToCompleteValid] = useState<boolean>(true);
  const [lotImageValid, setLotImageValid] = useState<boolean>(true);
  const [lotBadgeValid, setLotBadgeValid] = useState<boolean>(true);
  const [lotDataSelect, setLotDataSelect] = useState<{ label: string; value: string }[]>([]);
  let lotData: Lot[] = [];

  const fetchLotData = async () => {
    lotData = await getAllLots();

    if (lotData !== undefined) {
      const lotDataSelect = lotData
        .filter((lot) => lot !== null)
        .map((lot) => ({ label: lot.title, value: lot._id, type: lot.type }));

      lotDataSelect.sort((a, b) => {
        const aL = a.label
          .toLocaleLowerCase()
          .normalize('NFD')
          .replace(/\p{Diacritic}/gu, '');
        const bL = b.label
          .toLocaleLowerCase()
          .normalize('NFD')
          .replace(/\p{Diacritic}/gu, '');
        if (aL < bL) {
          return -1;
        }
        if (aL > bL) {
          return 1;
        }
        return 0;
      });

      const indexSolution = lotDataSelect.findIndex((l) => l.type === 'solution');
      lotDataSelect.unshift(lotDataSelect[indexSolution]);
      lotDataSelect.splice(indexSolution + 1, 1);

      const indexFactuel = lotDataSelect.findIndex((l) => l.type === 'factuel');
      lotDataSelect.unshift(lotDataSelect[indexFactuel]);
      lotDataSelect.splice(indexFactuel + 1, 1);

      const indexIntro = lotDataSelect.findIndex((l) => l.type === 'introduction');
      lotDataSelect.unshift(lotDataSelect[indexIntro]);
      lotDataSelect.splice(indexIntro + 1, 1);

      setLotDataSelect(lotDataSelect);
    }
  };

  useEffect(() => {
    fetchLotData();

    // set the lot coming from the store after a user has selected 'poursuivre' in the gestion des publications
    if (lotDataSelect && editLot !== undefined && editLot._id !== undefined && editLot._id !== '') {
      setSelectedLotInfo(editLot._id);
    }
    async function fetchLotTypes() {
      const lotTypes = await getLotTypes();
      setExistingLotTypes(lotTypes);
    }
    fetchLotTypes();
  }, []);

  useEffect(() => {
    setIsSubmitDisabled(
      !(
        editLotTitle &&
        editLotTimeToComplete &&
        editLotImage &&
        editLotBadge &&
        lotTitleValid &&
        lotTimeToCompleteValid &&
        lotImageValid &&
        lotBadgeValid
      )
    );
  }, [
    editLotTitle,
    editLotTitleWasChanged,
    editLotTimeToComplete,
    editLotTimeToCompleteWasChanged,
    editLotImage,
    lotImageWasChanged,
    editLotBadge,
    lotBadgeWasChanged,
    lotTitleValid,
    lotTimeToCompleteValid,
    lotImageValid,
    lotBadgeValid
  ]);

  async function submitOrGoNext() {
    if (
      editLotTitleWasChanged ||
      editLotTimeToCompleteWasChanged ||
      lotImageWasChanged ||
      lotBadgeWasChanged ||
      lotTypeWasChanged
    ) {
      if (editLotTitleWasChanged) {
        const titleAlreadyExist = await checkLotTitle(editLotTitle ?? '');
        if (!titleAlreadyExist.exists) {
          await submitEditLot();
        }
      } else {
        await submitEditLot();
      }
    } else {
      dispatch(lotType === LotTypeEnum.INTRODUCTION ? setEditionStepToVideo() : setEditionStepToMap());
    }
  }

  async function submitEditLot() {
    if (editLot._id && editLotTitle && editLotTimeToComplete && editLotImage && editLotBadge && lotType) {
      const formData = new FormData();
      let imageFileUploaded;
      let badgeFileUploaded;
      let editedLot;

      try {
        if (lotImageWasChanged) {
          imageFileUploaded = await addFile(editLotImage);
          formData.append('image', `${process.env.REACT_APP_PATH}/${imageFileUploaded.filename}`);
        } else if (lotInfo && lotInfo.imageUrl) {
          formData.append('image', lotInfo.imageUrl);
        }

        if (lotBadgeWasChanged) {
          badgeFileUploaded = await addFile(editLotBadge);
          formData.append('badge', `${process.env.REACT_APP_PATH}/${badgeFileUploaded.filename}`);
        } else if (lotInfo && lotInfo.badgeUrl) {
          formData.append('badge', lotInfo.badgeUrl);
        }

        formData.append('_id', editLot._id);
        formData.append('title', editLotTitle);
        formData.append('timeToComplete', editLotTimeToComplete);
        formData.append('type', lotType);

        editedLot = await editLotApi(editLot._id, formData) as Lot;

        if (editedLot) {
          // go through the lotData and find the lot that was edited and update it
          const lotIndex = lotData.findIndex((lot) => lot._id === editLot._id);
          lotData[lotIndex] = editedLot;
          // go through the lotDataSelect and find the lot that was edited and update it
          const lotSelectIndex = lotDataSelect.findIndex((lot) => lot.value === editLot._id);
          lotDataSelect[lotSelectIndex] = { label: editedLot.title, value: editedLot._id };
          dispatch(setNotificationType('validation'));
          dispatch(setNotificationMessage('Votre lot a bien été modifié'));
          dispatch(lotType === LotTypeEnum.INTRODUCTION ? setEditionStepToVideo() : setEditionStepToMap());
        }
      } catch (error) {
        console.log(error);
        if (imageFileUploaded) {
          await deleteFile(imageFileUploaded.path);
        }
        if (badgeFileUploaded) {
          await deleteFile(badgeFileUploaded.path);
        }
      }
    }
  }

  async function handleSelectChange(newValue: unknown) {
    const newValueTyped = newValue as { label: string; value: string };
    setSelectedLotInfo(newValueTyped.value);
  }

  const handleLotTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (lotInfo && event.target.value !== lotInfo.type) {
      setLotTypeWasChanged(true);
    } else {
      setLotTypeWasChanged(false);
    }
    setLotType(event.target.value as LotTypeEnum);
  };

  async function setSelectedLotInfo(lotId: Lot['_id']) {
    const lotInfo = await getLotById(lotId);
    setLotInfo(lotInfo);
    setEditLotTitle(lotInfo.title);
    setEditLotTimeToComplete(lotInfo.timeToComplete);
    dispatch(setEditLot(lotInfo));

    const imageBlob = await getFile(lotInfo.imageUrl);
    const badgeBlob = await getFile(lotInfo.badgeUrl);

    setEditLotImage(new File([imageBlob as Blob], lotInfo.imageUrl.split('/').at(-1)));
    setEditLotBadge(new File([badgeBlob as Blob], lotInfo.badgeUrl.split('/').at(-1)));

    setLotType(lotInfo.type);

    // Set the selected lot state
    setSelectedLot({ label: lotInfo.title, value: lotInfo._id });
  }

  async function removeLot() {
    if (selectedLot && window.confirm('Êtes-vous sûr de vouloir supprimer ce lot ?')) {
      dispatch(setNotificationType('loading'));
      dispatch(setNotificationMessage('Suppression du lot en cours...'));

      await deleteLot(selectedLot.value);

      dispatch(setNotificationType('validation'));
      dispatch(setNotificationMessage('Le lot a bien été supprimé'));

      window.location.reload();
    }
  }

  return (
    <>
      <DropDownInput
        options={lotDataSelect}
        placeholder={t('dropDownPlaceholder')}
        styles={customDropDownStyles}
        onChange={handleSelectChange}
        value={selectedLot}
      />
      {selectedLot && (
        <Button
          label="Supprimer le lot"
          type="button"
          classType="primary-button-lg"
          translation="createCard"
          onClick={removeLot}
        />
      )}
      <GenericLotForm
        isEdit
        titleInfo={{
          onChange: setEditLotTitle,
          existingValue: editLotTitle,
          placeholder: t('editTitlePlaceholder'),
          setValidity: setLotTitleValid,
          setEditLotTitleWasChanged: setEditLotTitleWasChanged
        }}
        timeToCompleteInfo={{
          onChange: setEditLotTimeToComplete,
          existingValue: editLotTimeToComplete,
          placeholder: t('editTimeToCompletePlaceholder'),
          setValidity: setLotTimeToCompleteValid,
          setEditLotTimeToCompleteWasChanged: setEditLotTimeToCompleteWasChanged
        }}
        imageInfo={{
          file: editLotImage,
          setFileVariable: setEditLotImage,
          setValidity: setLotImageValid,
          lotImageWasChanged: setLotImageWasChanged
        }}
        badgeInfo={{
          file: editLotBadge,
          setFileVariable: setEditLotBadge,
          setValidity: setLotBadgeValid,
          lotBadgeWasChanged: setLotBadgeWasChanged
        }}
        submitLot={submitOrGoNext}
        lotType={lotType}
        onLotTypeChange={handleLotTypeChange}
        existingLots={existingLotTypes ? existingLotTypes : null}
        editLotSelected={selectedLot}
        isSubmitDisabled={isSubmitDisabled}
      />
    </>
  );
}
