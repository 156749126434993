import { Card, Lot, Map } from 'constants/index';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'redux/hooks';
import { selectMaps, selectZones, setMaps } from 'redux/slices/adminMap';
import { setLots } from 'redux/slices/manageLots';
import { selectUser, setCompletedLots } from 'redux/slices/user';
import { getAllLots } from 'services/lot/lotService';
import { useZoneRefs } from 'views/components/base/map/map/editZonesHooks';
import { Map as MapComponent } from 'views/components/base/map/map/map';
import { getMapsWithImagesAPI } from '../../utils/maps/mapsWithImages';
import { MapsRowList } from '../maps/mapsRowList/mapsRowList';
import { getAllCards } from '../../services/card/cardService';
import { getUserCompletedLots } from '../../services/user/userServices';

export function Progression() {
  const dispatch = useDispatch();
  const zones = useAppSelector(selectZones);
  const user = useAppSelector(selectUser);
  const { zoneRefs } = useZoneRefs(zones);
  const maps = useAppSelector(selectMaps);
  const [cards, setCards] = useState<Card[]>();
  const [selectedMap, setSelectedMap] = useState<Map>();

  const getLotsAndCards = async (selectedMap: Map) => {
    const lots: Lot[] = await getAllLots();
    const cards: Card[] = await getAllCards();
    const { lotsFinished } = (await getUserCompletedLots(user.id)) ?? {};

    if (lots && lotsFinished && cards) {
      const lotsCompletedForMap = lots.filter((lot) => lot.mapId === selectedMap._id && lotsFinished.includes(lot._id));

      const cardsCompletedForMap = cards.filter((card) =>
        lotsCompletedForMap.some((lot) => lot.cards.map((card) => card._id).includes(card._id))
      );
      setCards(cardsCompletedForMap);

      dispatch(setLots(lots));
      dispatch(setCompletedLots(lotsCompletedForMap.map((lot) => lot._id)));
    }
  };

  useEffect(() => {
    if (maps.length === 0) {
      getMapsWithImagesAPI().then((maps) => {
        dispatch(setMaps(maps));
        setSelectedMap(maps[0]);
      });
    } else if (!selectedMap) {
      setSelectedMap(maps[0]);
    }
  }, [maps, selectedMap, user.id]);

  useEffect(() => {
    if (selectedMap) {
      getLotsAndCards(selectedMap);
    }
  }, [selectedMap]);

  return (
    <>
      <MapsRowList maps={maps} selectedMap={selectedMap} setSelectedMap={setSelectedMap} />
      <MapComponent
        cards={cards}
        map={selectedMap}
        zoneRefs={zoneRefs}
        isPreview
        isProgression
        isGame={false}
        isAdminEdit={false}
      />
    </>
  );
}
