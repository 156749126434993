import { Card, Map } from 'constants/index';
import { apiUtils } from 'services/utils';

export const createCard = async (formData: FormData) => {
  try {
    const card = await apiUtils.apiPost(`${process.env.REACT_APP_API_URL}/cards`, formData);
    return card;
  } catch (error) {
    console.log(error);
  }
};

export const getCardById = async (id: string) => {
  try {
    const card = await apiUtils.apiGet(`${process.env.REACT_APP_API_URL}/cards/${id}`);
    return card;
  } catch (error) {
    console.log(error);
  }
};

export const getAllCards = async () => {
  try {
    const cards = await apiUtils.apiGet(`${process.env.REACT_APP_API_URL}/cards`);
    return cards;
  } catch (error) {
    console.log(error);
  }
};

export const addCardToLot = async (id: number) => {
  try {
    const addedCardToLot = await apiUtils.apiPut(`${process.env.REACT_APP_API_URL}/cards/${id}/addcardtolot`);
    return addedCardToLot;
  } catch (error) {
    console.log(error);
  }
};

export const updateCards = async (lotId: string, cards: Card[]) => {
  try {
    const cardsUpdated = await apiUtils.apiPut(`${process.env.REACT_APP_API_URL}/cards`, {
      cards,
      lotId
    });
    return cardsUpdated;
  } catch (error) {
    console.log(error);
  }
};

export const updateCardById = async (id: string, card: Card) => {
  try {
    const cardUpdated = await apiUtils.apiPut(`${process.env.REACT_APP_API_URL}/cards/${id}/`, card);
    return cardUpdated;
  } catch (error) {
    console.log(error);
  }
};

export const deleteCard = async (id: string) => {
  try {
    const response = await apiUtils.apiDelete(`${process.env.REACT_APP_API_URL}/cards/${id}`);
    return response;
  } catch (error) {
    console.log(error);
  }
};
