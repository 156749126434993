import { ReactComponent as LeftArrow } from 'assets/icons/arrow-left-icon.svg';
import { ReactComponent as RightArrow } from 'assets/icons/arrow-right-icon.svg';
import { ReactComponent as StepOne } from 'assets/icons/step-icon-one.svg';
import { ReactComponent as StepTwo } from 'assets/icons/step-icon-two.svg';
import { Card, CardType, Positions, TutorialType } from 'constants/index';
import { useEffect, useState } from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import {
  resetState,
  selectIsDrag,
  selectLot,
  selectTutorialType,
  setBlinking,
  setIsDrag,
  setStepToMap
} from 'redux/slices/game';
import { Button } from 'views/buttons/base';
import { Card as CardComponent } from 'views/components/base/cards/Card';
import './tutorialMapControls.scss';

export function TutorialMapControls({
  card,
  onCardDragStop,
  onStepOne,
  onFindZone
}: {
  card: Card;
  onCardDragStop: (zoneId: string, card: Card) => boolean;
  onStepOne: () => unknown;
  onFindZone: () => unknown;
}) {
  const dispatch = useDispatch();
  const currentLot = useAppSelector(selectLot);
  const tutorialType = useAppSelector(selectTutorialType);
  const isDrag = useAppSelector(selectIsDrag);

  const navigate = useNavigate();
  const [currentTutorialStep, setCurrentTutorialStep] = useState<number>(0);
  const [isCardLoaded, setIsCardLoaded] = useState<boolean>(false);
  const [showCardBorder, setShowCardBorder] = useState<boolean>(false);
  const [draggableCardPosition, setDraggableCardPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [shrinkCard, setShrinkCard] = useState<boolean>(false);
  const [isShown, setIsShown] = useState<boolean>(false);

  useEffect(() => {
    if (card) {
      setIsCardLoaded(true);
    }
  }, [currentTutorialStep, card]);

  useEffect(() => {
    if (currentTutorialStep === 2) {
      setTimeout(() => {
        setIsShown(true);
      }, 1500);
    }
  }, [currentTutorialStep]);

  const stepOne = (
    <>
      <StepOne />
      <p>
        <b>Lisez la carte</b>
      </p>
    </>
  );
  const stepTwo = (
    <>
      <StepTwo />
      <p>
        <b>Placez la carte</b> à l’endroit où cela vous semble pertinent, en la faisant <b>glisser sur une zone</b>{' '}
        carte factuel.
      </p>
    </>
  );
  const stepFour = (
    <div className="finished">
      {tutorialType === TutorialType.EXAMPLE && (
        <>
          <p>Le tutoriel est terminé, vous pouvez retourner a l'accueil.</p>
        </>
      )}
      {tutorialType !== TutorialType.EXAMPLE && (
        <>
          <p>Le tutoriel est terminé, vous allez pouvoir placer le reste des cartes.</p>
          <p className="bonasavoir" style={{ color: '#E74AAD' }}>
            Bon à savoir !
          </p>
          <p>Vous pouvez retrouver le tutoriel à tout moment dans votre navigation.</p>
        </>
      )}
    </div>
  );

  const [tutorialSteps] = useState<{ [key: number]: any }>({
    0: stepOne,
    1: stepTwo,
    2: null, // step 2 need a render after x second we can't put the component in the array
    3: stepFour
  });

  const startGame = () => {
    dispatch(resetState());
    dispatch(setStepToMap());
  };

  const backToHome = () => {
    navigate('/');
  };

  const nextStep = () => {
    if (currentTutorialStep + 1 === 1) {
      onFindZone();
    }

    if (currentTutorialStep === 1) {
      // Show no-card border
      setShowCardBorder(true);
      setCurrentTutorialStep(currentTutorialStep + 1);
    } else {
      // Go to next tutorial step
      setCurrentTutorialStep(currentTutorialStep + 1);
    }
  };

  const previousStep = () => {
    // 'If' conditions are here to reset
    // behavior of tutorial steps
    if (currentTutorialStep - 1 === 1) {
      // Make no-card border disappear
 
      setShowCardBorder(false);
      const isReloading = onStepOne();
      if (isReloading) {
        setIsCardLoaded(false);
        setIsShown(false)
      }
    }
    setCurrentTutorialStep(currentTutorialStep - 1);
  };

  const moveToCursor = (e: any, el: DraggableData) => {
    let box = el.node.getBoundingClientRect();
    let mouse_top = e.clientY;
    let mouse_left = e.clientX;
    let diff_x = mouse_left - box.left;
    let diff_y = mouse_top - box.top;
    el.node.style.top = Number(el.node.style.top.replace('px', '')) - 1 + diff_y + 'px';
    el.node.style.left = Number(el.node.style.left.replace('px', '')) - 1 + diff_x + 'px';
  };

  return (
    <div className="tutorial-map-controls__container">
      <span className="tutorial-map-controls__container__title">Tutoriel</span>
      <div className="tutorial-map-controls__step-container">
        {currentTutorialStep !== 3 && (
          <>
            <div>
              <p>
                <b>Placer</b> la carte dans une zone sur la map.
              </p>
            </div>
          </>
        )}
        {currentTutorialStep === 2 && (
          <div className="step">
            <div className="bravo">
              <p style={{ color: '#149228' }}>
                Bravo !
              </p>
              <p>Vous avez bien placé votre première carte dans sa zone.</p>
              {isShown && (
                <>
                  <p className='zone-card'>Si vous souhaitez revoir la carte, cliquer sur la zone</p>
                </>
              )}
            </div>
          </div>
        )}
        {currentTutorialStep !== 2 && <div className="step">{tutorialSteps[currentTutorialStep]}</div>}
      </div>
      <div className={showCardBorder ? 'tutorial-map-controls__no-card-border-factual' : ''}>
        {currentTutorialStep >= 0 && currentTutorialStep < 2 && isCardLoaded && (
          <div key={card._id} className={isDrag ? `game-map-controls__no-card-border-factual isDragged` : ''}>
            <Draggable
              disabled={currentTutorialStep === 0}
              position={draggableCardPosition}
              defaultClassName="relative"
              onStart={(e, el) => {
                moveToCursor(e, el);
                dispatch(setIsDrag(true));
              }}
              onStop={(e, el) => {
                el.node.style.top = '0px';
                el.node.style.left = '0px';
                setShrinkCard(false);
                dispatch(setIsDrag(false));
                const draggableElement = e.target as HTMLElement;
                if (draggableElement && onCardDragStop) {
                  const zoneId = draggableElement.getAttribute('data-id');
                  if (zoneId) {
                    const hasBeenDroppedOnZone = onCardDragStop(zoneId, card);
                    if (hasBeenDroppedOnZone) {
                      nextStep();
                      // if the card was dropped on a zone, replace the draggable element at the original position for the next card
                      setDraggableCardPosition({ x: 0, y: 0 });
                      dispatch(setBlinking(false));
                    }
                  }
                }
              }}
              onDrag={(e: DraggableEvent, positionData) => {
                if (positionData.x < -50) {
                  setShrinkCard(true);
                  dispatch(setBlinking(false));
                }
              }}
            >
              <div className={`game-map-controls__card-container disable-link ${!isDrag ? 'flex-display' : ''}`}>
                <CardComponent
                  cardType={CardType.map}
                  card={card}
                  shrinkCard={shrinkCard}
                  tutorial={currentTutorialStep === 0}
                />
              </div>
            </Draggable>
          </div>
        )}
      </div>
      <div className="tutorial-map-controls__buttons">
        {currentTutorialStep > 0 && (
          <Button
            label="previous"
            type="button"
            classType="secondary-button-lg"
            translation="game"
            onClick={previousStep}
            Icon={{
              Svg: LeftArrow,
              position: Positions.START
            }}
          />
        )}
        {currentTutorialStep < Object.keys(tutorialSteps).length - 1 && (
          <Button
            label="next"
            type="button"
            disabled={currentTutorialStep === 1 || currentTutorialStep === 2 && !isShown}
            classType={'primary-button-lg'}
            translation="game"
            onClick={nextStep}
            Icon={{
              Svg: RightArrow,
              position: Positions.END
            }}
          />
        )}
        {tutorialType === TutorialType.GAME && currentTutorialStep === Object.keys(tutorialSteps).length - 1 && (
          <Button label="begin" type="button" classType="primary-button-lg" translation="game" onClick={startGame} />
        )}
        {tutorialType === TutorialType.EXAMPLE && currentTutorialStep === Object.keys(tutorialSteps).length - 1 && (
          <Button
            label="backHome"
            type="button"
            classType="primary-button-lg"
            translation="game"
            onClick={backToHome}
          />
        )}
      </div>
    </div>
  );
}
