import React, { useEffect, useState } from 'react';
import { checkLotTitle, createLot, getLotTypes } from 'services/lot/lotService';
import { useAppDispatch } from 'redux/hooks';
import {
  setCreatedLot,
  setCreationStepToVideos,
  setCreationStepToMap,
  setIsFromEditCards
} from 'redux/slices/lotCreation';
import { setNotificationMessage, setNotificationType } from 'redux/slices/notification';
import { GenericLotForm } from 'views/components/base/form/genericLotForm';
import { addFile, deleteFile } from 'services/file/fileService';
import { LotType as LotTypeEnum, LotTypeObject } from 'constants/index';

export function CreateLotForm() {
  const dispatch = useAppDispatch();

  // form state
  const [lotTitle, setLotTitle] = useState<string | undefined>();
  const [lotTimeToComplete, setLotTimeToComplete] = useState<string | undefined>();
  const [lotImage, setLotImage] = useState<File | undefined>();
  const [lotBadge, setLotBadge] = useState<File | undefined>();
  const [lotType, setLotType] = useState<LotTypeEnum>(LotTypeEnum.THEMATIQUE);
  const [existingLotTypes, setExistingLotTypes] = useState<LotTypeObject>();

  //form validation state
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
  const [lotTitleValid, setLotTitleValid] = useState<boolean>(false);
  const [lotTimeToCompleteValid, setLotTimeToCompleteValid] = useState<boolean>(false);
  const [lotImageValid, setLotImageValid] = useState<boolean>(true);
  const [lotBadgeValid, setLotBadgeValid] = useState<boolean>(true);

  useEffect(() => {
    async function fetchLotTypes() {
      const lotTypes = await getLotTypes();
      setExistingLotTypes(lotTypes);
    }
    fetchLotTypes();
  }, []);

  useEffect(() => {
    setIsSubmitDisabled(
      !(
        lotTitle &&
        lotTimeToComplete &&
        lotImage &&
        lotBadge &&
        lotTitleValid &&
        lotTimeToCompleteValid &&
        lotImageValid &&
        lotBadgeValid
      )
    );
  }, [
    lotTitle,
    lotTimeToComplete,
    lotImage,
    lotBadge,
    lotTitleValid,
    lotTimeToCompleteValid,
    lotImageValid,
    lotBadgeValid
  ]);

  const handleLotTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLotType(event.target.value as LotTypeEnum);
  };

  async function submitLot() {
    const formData = new FormData();
    const titleAlreadyExist = await checkLotTitle(lotTitle ?? '');

    if (!lotTitle || !lotTimeToComplete || !lotImage || !lotBadge || titleAlreadyExist.exists) {
      return;
    }
    let newCreatedLot = null;
    let imageFileUploaded = null;
    let badgeFileUploaded = null;

    try {
      dispatch(setNotificationType('loading'));
      dispatch(setNotificationMessage('Création du lot en cours...'));

      formData.append('type', lotType);
      formData.append('title', lotTitle);
      formData.append('timeToComplete', lotTimeToComplete);

      imageFileUploaded = await addFile(lotImage);
      badgeFileUploaded = await addFile(lotBadge);

      formData.append('image', `${process.env.REACT_APP_PATH}/${imageFileUploaded.filename}`);
      formData.append('badge', `${process.env.REACT_APP_PATH}/${badgeFileUploaded.filename}`);

      newCreatedLot = await createLot(formData);

      dispatch(setNotificationType('validation'));
      dispatch(setNotificationMessage('Votre lot a bien été créé'));
      dispatch(setCreatedLot(newCreatedLot));

      if (lotType === LotTypeEnum.INTRODUCTION) {
        dispatch(setCreationStepToVideos());
      } else {
        dispatch(setIsFromEditCards(false));
        dispatch(setCreationStepToMap());
      }
    } catch (error) {
      console.log(error);
      if (imageFileUploaded) {
        deleteFile(imageFileUploaded.path);
      }
      if (badgeFileUploaded) {
        deleteFile(badgeFileUploaded.path);
      }
    }
  }

  return (
    <GenericLotForm
      isEdit={false}
      titleInfo={{ onChange: setLotTitle, setValidity: setLotTitleValid }}
      timeToCompleteInfo={{ onChange: setLotTimeToComplete, setValidity: setLotTimeToCompleteValid }}
      imageInfo={{ file: lotImage, setFileVariable: setLotImage, setValidity: setLotImageValid }}
      badgeInfo={{ file: lotBadge, setFileVariable: setLotBadge, setValidity: setLotBadgeValid }}
      submitLot={submitLot}
      lotType={lotType}
      onLotTypeChange={handleLotTypeChange}
      existingLots={existingLotTypes ? existingLotTypes : null}
      isSubmitDisabled={isSubmitDisabled}
      editLotSelected={null}
    />
  );
}
