import { CardPopupType, Menus, SectorStepPopup, SectorTypePopup, ZonesTypeMap } from 'constants/index';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setCurrentTab } from 'redux/slices/adminMenu';
import { resetLotCreationState } from 'redux/slices/lotCreation';
import { editRemoveCards, setContinueEditId, setEditionStepToEditLot } from 'redux/slices/lotEdition';
import { changeLotStatus } from 'redux/slices/manageLots';
import { setNotificationMessage, setNotificationType } from 'redux/slices/notification';
import { selectPopupData, selectPopupDataForRollback, setPopupData, setType } from 'redux/slices/popup';
import { setUserLogout } from 'redux/slices/user';
import { selectZonesType } from 'redux/slices/zones';
import { deleteCard } from 'services/card/cardService';
import { publishLot, unpublishLot } from 'services/lot/lotService';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close-icon.svg';
import { CardPopUp } from '../card/CardPopup';
import { ZonePopUp } from '../zone/zonePopUp';
import { SectorPopup } from '../sector/SectorPopup';
import './Popup.scss';
import { resetState, setBlinking, setCardsPopup } from 'redux/slices/game';
import { ZoneEdit } from '../zone/zoneEdit/zoneEdit';
import { Button } from 'views/buttons/base';
import {
  goTo,
  pubSector,
  removeSector,
  selectSector,
  setConfirmDeleteStep,
  setSector,
  unpubSector
} from 'redux/slices/manageSectors';
import { deleteSector, publishSector, unpublishSector } from 'services/sectors/sectorService';
import { ManageStep } from 'constants/enums';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { setIsLogoutActive } from 'redux/slices/navbar';
import { UserInformationPopup } from '../user/UserInformationPopup';
import { NewMap } from '../map/new-map/newMap';

// NOTE: Type parameter could be changed to a enum
export function Popup({ type }: { type: string }) {
  const { t } = useTranslation(['popup']);
  const dispatch = useAppDispatch();
  const popupCloseDuration = 2500;
  const data = useAppSelector(selectPopupData);
  const dataForRollback = useAppSelector(selectPopupDataForRollback); //Usefull for open image to return to previous modal
  const zonesType = useAppSelector(selectZonesType);
  const sector = useAppSelector(selectSector);
  const popupRef = useRef(null);

  let result = null;

  useOutsideClick(popupRef, () => dispatch(setType('')));

  useEffect(() => {
    if (type === 'deconnexion-token') {
      setTimeout(() => {
        dispatch(setType(''));
      }, popupCloseDuration);
    } else if (type === 'zoom-image') {
      dispatch(setCardsPopup(true));
    }

    return () => {
      if (type === 'zoom-image') {
        data.imageUrl ? URL.revokeObjectURL(data.imageUrl) : (data.imageUrl = '');
      }
    };
  });

  const onClickValidationEvent = async () => {
    dispatch(setType(''));
    window.localStorage.removeItem('token');
    dispatch(setUserLogout());
    dispatch(setIsLogoutActive(false));
    window.location.reload();
  };

  const onClickCloseEvent = async () => {
    if (type === 'deconnexion') {
      dispatch(setIsLogoutActive(false));
    }
    dispatch(setType(''));
  };

  const onClickDeleteCardEvent = async () => {
    await deleteCard(data.cardId).then(() => {
      dispatch(editRemoveCards(data.cardId));
      dispatch(setType(''));
      dispatch(setNotificationType('validation'));
      dispatch(setNotificationMessage('La carte a bien été supprimée'));
      dispatch(setPopupData(undefined));
    });
  };

  const setMenuToEdit = () => {
    dispatch(resetLotCreationState());
    dispatch(setEditionStepToEditLot());
    dispatch(setCurrentTab(Menus.EDIT));
    dispatch(setType(''));
  };

  const onClickCloseImage = async () => {
    dispatch(setBlinking(true));
    dispatch(setType('displayZoneCards'));
    dispatch(setPopupData(dataForRollback));
  };

  const onClickLotEvent = async (action: string) => {
    let status = 'unpublished';
    if (action === 'publish') {
      status = 'published';
    } else if (action === 'unpublish') {
      status = 'unpublished';
    }

    try {
      await Promise.resolve(action === 'publish' ? publishLot(data.lot._id) : unpublishLot(data.lot._id));
      if (data.isCreate) {
        dispatch(setType('validatePublication'));
      } else {
        dispatch(changeLotStatus({ lotId: data.lot._id, status }));
        dispatch(setType(''));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const continueEditLot = () => {
    dispatch(setContinueEditId(data.lot._id));
    dispatch(setCurrentTab(Menus.EDIT));
    dispatch(setType(''));
  };

  const onClickQuitMap = () => {
    dispatch(setType(''));
    dispatch(setCurrentTab(data));
    dispatch(setPopupData(undefined));

    history.replaceState({}, document.title, './admin');
  };

  const onClickDeleteSector = async () => {
    await deleteSector(sector?._id!)
      .then(() => {
        dispatch(removeSector());
        dispatch(setNotificationType('validation'));
        dispatch(setNotificationMessage('Le secteur / métier a bien été supprimé'));
      })
      .catch((e) => {
        console.error(e);
        dispatch(setNotificationType('error'));
        dispatch(setNotificationMessage('Une erreur est survenue'));
      })
      .finally(() => dispatch(setType('')));
  };

  const onClickUnpublishSector = async () => {
    await unpublishSector(sector?._id!)
      .then(() => {
        dispatch(unpubSector());
        dispatch(setNotificationType('validation'));
        dispatch(setNotificationMessage('Le secteur / métier a bien été dépublié'));
      })
      .catch((e) => {
        console.error(e);
        dispatch(setNotificationType('error'));
        dispatch(setNotificationMessage('Une erreur est survenue'));
      })
      .finally(() => dispatch(setType('')));
  };

  const onClickPublishSector = async () => {
    await publishSector(sector?._id!)
      .then(() => {
        dispatch(pubSector());
        dispatch(setNotificationType('validation'));
        dispatch(setNotificationMessage('Le secteur / métier a bien été publié'));
      })
      .catch((e) => {
        console.error(e);
        dispatch(setNotificationType('error'));
        dispatch(setNotificationMessage('Une erreur est survenue'));
      })
      .finally(() => dispatch(setType('')));
  };

  const goToSteps = async () => {
    dispatch(setSector(data.sector));
    dispatch(setCurrentTab(Menus.STEPS));
    dispatch(goTo(ManageStep.detail));
    dispatch(setType(''));
  };

  const confirmDeleteStep = () => {
    dispatch(setConfirmDeleteStep(true));
    dispatch(setType(''));
  };
  switch (type) {
    case 'validation':
      result = (
        <div className="container-popup">
          <div className="validation">
            <h1>{t('logoutWarning')}</h1>
            <a href="#">{t('validate')}</a>
            <a href="#">{t('cancel')}</a>
            <CloseIcon className="deconnexion__close" onClick={onClickCloseEvent} />
          </div>
        </div>
      );
      break;

    case 'deconnexion':
      result = (
        <div className="container-popup">
          <div className="deconnexion">
            <p className="deconnexion__warning">{t('logoutWarning')}</p>
            <Link className="deconnexion__link" to="/" onClick={onClickValidationEvent}>
              {t('validate')}
            </Link>
            <Link className="deconnexion__link" to="#" onClick={onClickCloseEvent}>
              {t('cancel')}
            </Link>
          </div>
        </div>
      );
      break;

    case 'deconnexion-token':
      result = (
        <div className="container-popup">
          <div className="deconnexion-token">
            <p className="deconnexion-token__warning">
              {t('tokenWarning')}
              <br />
              <br />
              {t('redirectionWarning')}
            </p>
            <CloseIcon className="deconnexion-token__close" onClick={onClickCloseEvent} />
          </div>
        </div>
      );
      break;

    case 'screen-size-error':
      result = (
        <div className="container-popup">
          <div className="screen-size">
            <p className="screen-size__warning">{t('screenWarning')}</p>
          </div>
        </div>
      );
      break;

    case 'addCard':
      result = (
        <div className="container-popup">
          <div className="add-card">
            <CardPopUp cardPopupType={CardPopupType.creation} blobImageSrc={data} />
          </div>
        </div>
      );
      break;

    case 'editCard':
      result = (
        <div className="container-popup">
          <div className="edit-card">
            <CardPopUp cardPopupType={CardPopupType.edition} blobImageSrc={data} />
          </div>
        </div>
      );
      break;

    case 'deleteCard':
      result = (
        <div className="container-popup">
          <div className="delete-card">
            <p className="delete-card__warning">{t('deleteCardWarning')}</p>
            <Link className="delete-card__link" to="#" onClick={onClickDeleteCardEvent}>
              {t('validate')}
            </Link>
            <Link className="delete-card__link" to="#" onClick={onClickCloseEvent}>
              {t('cancel')}
            </Link>
          </div>
        </div>
      );
      break;

    case 'publishLot':
      result = (
        <div className="container-popup">
          <div className="publication">
            <p className="publication__warning">{t('publishLot')}</p>
            <Link
              className="publication__link"
              to="#"
              onClick={() => {
                onClickLotEvent('publish');
              }}
            >
              {t('validate')}
            </Link>
            <Link className="publication__link" to="#" onClick={onClickCloseEvent}>
              {t('cancel')}
            </Link>
          </div>
        </div>
      );
      break;

    case 'validatePublication':
      result = (
        <div className="container-popup">
          <div className="validate">
            {zonesType === ZonesTypeMap.creation && (
              <p className="validate__warning-center">
                Bravo !
                <br />
                Le {data.lot.title} est publié.
              </p>
            )}
            {zonesType === ZonesTypeMap.edition && (
              <p className="validate__warning-center">
                Bravo !
                <br />
                Le {data.lot.title} est modifié et publié.
              </p>
            )}
            <Link className="validate__link publish" to="admin" onClick={setMenuToEdit}>
              {t('backToAdmin')}
            </Link>
          </div>
        </div>
      );
      break;

    case 'unpublishLot':
      result = (
        <div className="container-popup">
          <div className="publication">
            <p className="publication__warning">{t('unpublishLot')}</p>
            <Link
              className="publication__link"
              to="#"
              onClick={() => {
                onClickLotEvent('unpublish');
              }}
            >
              {t('validate')}
            </Link>
            <Link className="publication__link" to="#" onClick={onClickCloseEvent}>
              {t('cancel')}
            </Link>
          </div>
        </div>
      );
      break;

    case 'continueEditLot':
      result = (
        <div className="container-popup">
          <div className="continue">
            <p className="continue__warning">{t('continueEditLot')}</p>
            <Link className="continue__link" to="#" onClick={continueEditLot}>
              {t('validate')}
            </Link>
            <Link className="continue__link" to="#" onClick={onClickCloseEvent}>
              {t('cancel')}
            </Link>
          </div>
        </div>
      );
      break;

    case 'quitUnsavedMap':
      result = (
        <div className="container-popup">
          <div className="unsaved-map">
            <p className="unsaved-map__warning">{t('unsavedMap')}</p>
            <a className="unsaved-map__link" onClick={onClickQuitMap}>
              {t('validate')}
            </a>
            <a className="unsaved-map__link" onClick={onClickCloseEvent}>
              {t('cancel')}
            </a>
          </div>
        </div>
      );
      break;

    case 'displayZoneCards':
      result = (
        <div className="container-popup" onClick={onClickCloseEvent}>
          <div className="display-zone-cards">
            <ZonePopUp
              factual={data.factual}
              solution={data.solution}
              thematique={data.thematique}
              lotType={data.lotType}
              isGame={data.isGame}
              textInfo={data.textInfo}
            />
          </div>
        </div>
      );
      break;

    case 'duplicateSector':
      result = (
        <div className="container-popup">
          <div className="duplicate-popup">
            <SectorPopup sectorTypePopup={SectorTypePopup.DUPLICATE} sectorStepPopup={SectorStepPopup.ACTION} />
          </div>
        </div>
      );
      break;

    case 'duplicateSectorConfirm':
      result = (
        <div className="container-popup">
          <div className="duplicate-popup-confirm">
            <SectorPopup sectorTypePopup={SectorTypePopup.DUPLICATE} sectorStepPopup={SectorStepPopup.CONFIRM} />
          </div>
        </div>
      );
      break;

    case 'selectSector':
      result = (
        <div className="container-popup">
          <div className="sector-selection-popup">
            <SectorPopup sectorTypePopup={SectorTypePopup.SELECTION} sectorStepPopup={SectorStepPopup.ACTION} />
          </div>
        </div>
      );
      break;

    case 'unlockNextStepConfirm':
      result = (
        <div className="container-popup">
          <div className="unlock-next-step-popup-confirm" ref={popupRef}>
            <CloseIcon className="unlock-next-step-popup-confirm__close" onClick={onClickCloseEvent} />
            <SectorPopup sectorTypePopup={SectorTypePopup.UNLOCK} sectorStepPopup={SectorStepPopup.CONFIRM} />
          </div>
        </div>
      );
      break;

    case 'lockedLot':
      result = (
        <div className="container-popup">
          <div className="locked-popup-confirm" ref={popupRef}>
            <CloseIcon className="locked-popup-confirm__close" onClick={onClickCloseEvent} />
            <SectorPopup sectorTypePopup={SectorTypePopup.LOCKED} sectorStepPopup={SectorStepPopup.CONFIRM} />
          </div>
        </div>
      );
      break;

    case 'validateBackToHome':
      result = (
        <div className="container-popup">
          <div className="home">
            <p className="home__warning">{t('backHomeFromGame')}</p>
            <Link
              className="validate__link"
              to="/"
              onClick={() => {
                dispatch(resetState());
                onClickCloseEvent();
              }}
            >
              {t('validate')}
            </Link>
            <a className="home__link" onClick={onClickCloseEvent}>
              {t('cancel')}
            </a>
          </div>
        </div>
      );
      break;

    case 'badgeUnlocked':
      result = (
        <div className="container-popup" onClick={onClickCloseEvent}>
          <div className="badge">
            <div>
              <CloseIcon className="badge__close" onClick={onClickCloseEvent} />
            </div>
            <div>
              <img className="badge__img" src={data.badgeUrl} />
              <p className="badge__p">
                {t('badgeUnlocked')} {data.lotType}
              </p>
            </div>
          </div>
        </div>
      );
      break;

    case 'badgeLocked':
      result = (
        <div className="container-popup" onClick={onClickCloseEvent}>
          <div className="badgelocked">
            <p className="badgelocked__warning">{t('badgeLocked')}</p>
            <Link
              className="validate__link"
              to={data.lotType === 'introduction' ? '/introduction' : `/game#${data.lotId}`}
              onClick={onClickCloseEvent}
            >
              {t('startLot')}
            </Link>
            <a className="badgelocked__link" onClick={onClickCloseEvent}>
              {t('cancel')}
            </a>
          </div>
        </div>
      );
      break;

    case 'editZone':
      result = (
        <div className="container-popup">
          <div className="display-zone-edit">
            <ZoneEdit />
          </div>
        </div>
      );
      break;

    case 'zoom-image':
      result = (
        <>
          {!data.fromModal && (
            <div className="container-popup" onClick={onClickCloseEvent}>
              <div className="zoom-image">
                <CloseIcon className="zoom-image__close" onClick={onClickCloseEvent} />
                <div className="zoom-image__title">{t('zoomImageTitle')}</div>
                <div className="zoom-image__container">
                  <img src={data.imageUrl} alt="image source" />
                </div>
              </div>
            </div>
          )}
          {data.fromModal && (
            <div className="container-popup">
              <div className="zoom-image">
                <CloseIcon className="zoom-image__close" onClick={onClickCloseImage} />
                <div className="zoom-image__title">{t('zoomImageTitle')}</div>
                <div className="zoom-image__container">
                  <img src={data.imageUrl} alt="image source" />
                </div>
                <div className="container-btn-rollback">
                  <Button
                    label="Retour aux cartes"
                    type="button"
                    classType="primary-button-lg game"
                    translation=""
                    onClick={onClickCloseImage}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      );
      break;

    case 'publishSector':
      result = (
        <div className="container-popup">
          <div className="finish-lot">
            <p className="finish-lot__warning">{t('publishSectorWarning')}</p>
            <a className="finish-lot__link" onClick={onClickPublishSector}>
              {t('validate')}
            </a>
            <a className="finish-lot__link" onClick={onClickCloseEvent}>
              {t('cancel')}
            </a>
          </div>
        </div>
      );
      break;

    case 'unpublishSector':
      result = (
        <div className="container-popup">
          <div className="finish-lot">
            <p className="finish-lot__warning">{t('unpublishSectorWarning')}</p>
            <a className="finish-lot__link" onClick={onClickUnpublishSector}>
              {t('validate')}
            </a>
            <a className="finish-lot__link" onClick={onClickCloseEvent}>
              {t('cancel')}
            </a>
          </div>
        </div>
      );
      break;

    case 'userInformation':
      result = (
        <div className="container-popup">
          <div className="user-information-popup">
            <UserInformationPopup />
          </div>
        </div>
      );
      break;

    case 'archiveSector':
      result = (
        <div className="container-popup">
          <div className="finish-lot">
            <p className="finish-lot__warning sector-warning">{t('archiveSectorWarning')}</p>
            <a className="finish-lot__link" onClick={onClickDeleteSector}>
              {t('validate')}
            </a>
            <a className="finish-lot__link" onClick={onClickCloseEvent}>
              {t('cancel')}
            </a>
          </div>
        </div>
      );
      break;

    case 'createdSector':
      result = (
        <div className="container-popup">
          <div className="finish-lot">
            <p className="text-success">Bravo !</p>
            <p className="finish-lot__warning sector-bravo">{t('sectorCreated')}</p>
            <a className="finish-lot__link" onClick={goToSteps}>
              {t('Allons-y')}
            </a>
            <a className="finish-lot__link" onClick={onClickCloseEvent}>
              {t('Pas maintenant')}
            </a>
          </div>
        </div>
      );
      break;

    case 'confirmDeleteStep':
      result = (
        <div className="container-popup">
          <div className="finish-lot">
            <p className="finish-lot__warning sector-bravo">{t('Êtes-vous sûr de vouloir supprimer cette étape ?')}</p>
            <a className="finish-lot__link" onClick={confirmDeleteStep}>
              {t('Valider')}
            </a>
            <a className="finish-lot__link" onClick={onClickCloseEvent}>
              {t('Annuler')}
            </a>
          </div>
        </div>
      );
      break;

    case 'addNewMap':
      result = (
        <div className="container-popup">
          <div className="display-new-map">
            <NewMap />
          </div>
        </div>
      );
      break;
    default:
      result = <></>;
      break;
  }

  return result;
}
