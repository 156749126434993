import { apiUtils } from 'services/utils';

export const getStats = async () => {
  try {
    const stats = await apiUtils.apiGet(`${process.env.REACT_APP_API_URL}/statistics`);
    return stats;
  } catch (error) {
    console.log(error);
  }
};

export const getCSV = async () => {
  try {
    const csv = await apiUtils.apiGet(`${process.env.REACT_APP_API_URL}/statistics/csv`);
    return csv;
  } catch (error) {
    console.log(error);
  }
};
